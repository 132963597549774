.profile-Mobile-start h3 {
    font-size: 24px;
    font-weight: 700;
    color: #000;
    margin-bottom: 0px;
}

.account-show {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.profile-offer-img img {
    width: 100%;
}

.profile-offer-img {
    margin: 1.25rem 0;
}

.offer-boredr {
    border: 5px solid #F9F9F9;
}

.account-show {
    padding-bottom: 20px;
}

.profile-text h2 {
    color: #000000;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 2px;
}

.profile-text p {
    font-size: 12px;
    margin-bottom: 2px;
}

.profile-img {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.profile-text {
    display: flex;
    align-items: center;
}

.profile-text-info {
    margin-left: 15px;
}

.profile-complection-text h5 {
    font-size: 12px;
    font-weight: 600;
    color: #000;
}

.voucher-section {
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;
    align-items: center;
}

.percentage p {
    margin-bottom: 0px;
    font-weight: 600;
    color: #000;
    font-size: 12px;
}

.offer-voucher-text p {
    font-size: 12px;
    margin: 10px 0px;
}

.profile-complection {
    /* background-color: #D6E3FF; */
    padding: 15px;
    border-radius: 10px;
}

.progress {
    height: 6px;
}

.offer-border {
    margin-bottom: 20px;
    border: 5px solid #F9F9F9;
}

.offer-border.mt-20 {
    margin-top: 20px;
}

.profile-account h2 {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0px;
}

.profile-img {
    display: flex;
    justify-content: space-between;
}

.profile-img.border-line-profile {
    border-bottom: 1px solid #EDE8E9;
}

.profile-img.border-line-profile {
    border-bottom: 1px solid #EDE8E9;
    /* margin: 18px 0px; */
    padding: 5px 0px;
    margin-bottom: 15px;
}

.profile-img.border-line-profile.border-none {
    border-bottom: none;
}


.toogle-checkbox .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.toogle-checkbox .switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.toogle-checkbox .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.toogle-checkbox .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

.toogle-checkbox input:checked+.slider {
    background-color: #234B9E;
}

.toogle-checkbox input:focus+.slider {
    box-shadow: 0 0 1px #234B9E;
}

.toogle-checkbox input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.toogle-checkbox .slider.round {
    border-radius: 34px;
}

.toogle-checkbox .slider.round:before {
    border-radius: 50%;
}

.border-right-tnc {
    color: #EDE8E9;
}


h2.logout-text {
    color: #DD0000;
}

.profile-text.profile-account {
    display: flex;
    align-items: center;
}

.profile-text.profile-account h4 {
    margin-bottom: 0px;
}

.mobile-filter-app .p-sidebar .p-sidebar-header .p-sidebar-close,
.p-sidebar .p-sidebar-header .p-sidebar-icon {
    position: relative;
    right: 8px;
}

.mobile-edit-profile {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mobile-cancel-btn-edit {
    font-size: 14px;
    border: none;
    border-radius: 8px;
    padding: 13px 63px;
    font-weight: 600;
    margin-right: 8px;
    font-family: 'Poppins';
    font-weight: 600;
    color: #000;
}

.mobile-save-btn-edit {
    padding: 13px 63px;
    font-size: 14px;
    border: none;
    border-radius: 8px;
    font-family: 'Poppins';
    margin-right: 10px;
    font-weight: 600;
}

.mobile-alert-btn-pvr input:checked+.slider {
    background-color: #ffcb05;
}

.mobile-alert-btn-inox input:checked+.slider {
    background-color: #234B9E;
}

.mobile-show-booking-profile .p-tabview .p-tabview-panels {
    padding: 0px 2px;
}

.mobile-booking-list ul li {
    list-style: none;
    float: left;
    margin-left: 5px;
    font-size: 14px;
}



/* UI CSS  */
.Account-view-show-mobile {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    align-items: center;
}

.account-angle-btn h4 {
    font-size: 20px;
    margin-bottom: 0px;
    font-weight: 600;
    color: #000;
}

.profile-mobile-box-spaces {
    padding: 0px 20px;
}

.profile-mobile-box-spaces-edit-profile {
    padding: 0px 20px;
    text-align: center;
    font-family: poppins;
}

.profile-complection .p-progressbar {
    background: #fff;
    height: 8px;
}

.profile-complection .p-progressbar-label {
    display: none;
}

.profile-show-mobile .p-sidebar-content {
    padding: 0px;
}

.user-mobile-show-details {
    text-align: center;
}

.user-detail-mobile-personal h4 {
    font-size: 21px;
    text-transform: capitalize;
    font-weight: 700;
    color: #000;
    margin-bottom: 4px;
}

.Personal-show-static {
    padding: 0px 20px;
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 500;
}

.Edit-profile-mobile .p-sidebar-content {
    padding: 0px;
}

.edit-profile-mobiles {
    font-size: 16px;
    font-weight: 700;
    color: #000;
}

.organisation-feild .dropdowns-type {
    font-size: 16px;
    font-weight: 700;
    color: #908e8e;
    font-family: poppins;
}

.p-button:enabled:hover,
.p-button:not(button):not(a):not(.p-disabled):hover {
    background: #fff;
    border-color: #fff;
    border: 1px solid #EDE8E9;
}

.Mybookinghistory-mobile .p-sidebar-content {
    padding: 0px !important;
}

.nobooking-available {
    text-align: center;
    font-family: 'Poppins';
    margin: 0px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.nobooking-available h6 {
    font-size: 14px;
    color: #000;
    margin-bottom: 7px;
}

.nobooking-available p {
    font-size: 14px;
    margin-bottom: 14px;
}

.my-book-no {
    border: none;
    padding: 7px 22px;
    border-radius: 30px;
    font-size: 14px;
    font-weight: 600;
}

.watch-list-mobile-box {
    display: flex;
    padding: 0px 20px 11px 20px;
    align-items: center;
    border-bottom: 1px solid #ccc;

}

.watch-list-mobile-box h4 {
    font-size: 20px;
    margin-bottom: 0px;
    font-weight: 600;
    color: #000;
    margin-left: 30px;
}

.watchlist-show-m .p-sidebar-content {
    padding: 0px;
}

.watchlistmobile-show {
    text-align: center;
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.watchlistmobile-show h4 {
    font-size: 16px;
    color: #000;
    font-weight: 500;
    margin-bottom: 5px;
}

.addmovies-mobile {
    border: none;
    padding: 10px 34px;
    border-radius: 30px;
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 600;
}

.past-booking-mobile {
    font-family: 'Poppins';
}

.mobile-booking-list ul {
    display: flex;
}

.Book-history-mobile h6 {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 2px;
}

.upcoming p {
    font-size: 14px;
    color: #000;
}

.show-date-timem-profile {
    font-size: 12px;
}

.past-mm {
    margin-right: 6px;
}

.pastbook-space-mobile {
    display: flex;
    padding: 11px;
}

.Terms-condition-mobile .p-sidebar-content {
    padding: 0px;
}

.policy-show-mobile {
    padding: 13px 20px;
    font-family: 'Poppins';
}

.Mobile-privacypolicy-show {
    display: flex;
    padding: 12px 13px 0px 20px;
    border-bottom: 1px solid #ddd;
}

.contactus-mobileform .p-sidebar-content {
    padding: 0px;
}

.Allform-apps {
    padding: 10px 20px 0px 20px;
    font-family: poppins;
    font-size: 14px;
}

.Threetypeform-app {
    padding: 0px 20px;
    font-family: poppins;
}

.provide-contact-update {
    display: flex;
    margin-bottom: 30px;
}

.rounded-circle {
    border-radius: 50% !important;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.rounded-circle-contact {
    border-radius: 50% !important;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.rounded-circle-last {
    border-radius: 50% !important;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.bgset-app {
    padding: 15px;
    margin-bottom: 15px;
}

.drawer-complete-mobile {
    padding: 0px 20px;
    display: flex;
    justify-content: space-between;
}

.drawer-complete-mobile h3 {
    text-align: center !important;
    font-size: 20px;
    font-weight: 700;
    color: #000;
}

.Show-progressbar-line-mobile {
    display: flex;
    flex-direction: column;
    padding: 0px 20px;
}

.mobile-profile-complete {
    padding: 0px 20px;
}

.mobile-profile-complete .p-progressbar {
    height: 10px;
    font-size: 0px;
    margin-top: 20px;
    margin-bottom: 20px;
}

/* UI CSS  */

.rounded-circle::before {
    content: "";
    border-width: 1.9px;
    border-style: dashed;
    border-color: #908E8E;
    height: 27px;
    position: relative;
    top: 30px;
    left: 8px;
}

.rounded-circle::after {
    border-width: 0 !important;
}


.watchlist-show-m .border.p-2.rounded.my-2 {
    margin: 15px;
}

.watchlist-show-m .notify-soon {
    margin: 0px 15px;
    font-family: poppins;
    color: #000;
}

.watchlist-show-m .border.rounded.my-2 {
    margin: 0px 15px 0px 15px;
    margin-bottom: 20px !important;
}

.watchlist-show-m .d-flex.m-alert {
    border: #EDE8E9;
    padding: 12px;
    background: #F9F9F9;
}

.watchlist-show-m {
    filter: drop-shadow(0px 1px 0px rgba(0, 0, 0, 0.40));
}

.watchlist-show-m .d-flex.justify-content-between.alert-set-info {
    padding: 5px 15px 11px 15px;
}


.item-movie-name {
    font-family: 'Poppins';
    font-weight: 600;
    color: #000;
}

.alert-set-for {
    font-family: 'Poppins';
    font-size: 13px;
    color: #000;
    font-weight: 400;
}

.delete-btn-info {
    margin-right: 15px;
}

.mx-3.delete-btn-info {
    margin-right: 0px !important;
}

.d-flex.m-alert {
    font-family: 'Poppins';
}

.Book-history-mobile {
    font-family: 'Poppins';
    color: #000;
    padding-left: 10px;
}

.add-food-history-mobile {
    color: #000;
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 500;
}

.mobile-mybooking-box {
    margin-bottom: 15px !important;
}

.book-history-date-m {
    display: flex;
    justify-content: space-between;
    padding: 7px 12px;
    font-family: poppins;
    font-size: 14px;
    color: #000;
    align-items: center;
}

.btn-btn-cancel-booking {
    border: none;
    padding: 9px 36px;
    font-size: 12px;
    font-family: 'Poppins';
    color: #C33737;
    font-weight: 600;
    border-radius: 30px;
}

.cancel-box {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.mobile-passport-ticket {
    margin-bottom: 1px;
}

.history-ticket-terms {
    border: 1px solid #ccc !important;
    /* padding: 14px !important; */
    border-radius: 21px !important;
    display: flex;
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 10px;
}

/*preferences tabs*/
.preference-active-tab-pvr {
    padding: 5px 10px;
    border-bottom: 2px solid #FFCB05;
    font-weight: 500;
    color: #FFCB05;
    cursor: pointer;
}

.preference-active-tab-inox {
    padding: 5px 10px;
    border-bottom: 2px solid #234B9E;
    font-weight: 500;
    color: #234B9E;
    cursor: pointer;
}

.preference-inactive-tab {
    padding: 5px 10px;
    border-bottom: 1px solid rgb(235, 234, 234);
    font-weight: 500;
    color: black;
    cursor: pointer;
}

.tab-view {
    width: -webkit-fill-available;
    padding-top: 21px;
    text-align: center;
}

.active-gener-lng-pvr {
    background-color: #FFCB05;
}

.active-gener-lng-inox {
    background-color: #234B9E;
    color: white;
}

.google-wallet-privilege-voucher-show-details {
    position: relative;
    padding: 10px;
    border-radius: 10px;
    /* background-image: linear-gradient(#1c1c1c, #272727); */
    background-image: linear-gradient(#1D468E, #1D468E);
    overflow: hidden;
    letter-spacing: 0;
    z-index: 0;
}

.google-wallet-privilege-voucher-show-details::after {
    position: absolute;
    inset: 0;
    background: url('../../../assets/payment/popcronbucket.png') no-repeat right center/contain;
    z-index: -1;
    content: "";
}

.google-wallet-privilege-id span {
    color: #fff;
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 600;
    /* margin-bottom: 14px; */
    display: block;
}

.google-wallet-privilege-id h6 {
    color: #828282;
    font-family: 'Poppins';
    font-size: 12px;
    margin-bottom: 8px;
}

.google-wallet-privilege-id p {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 14px;
}

.google-wallet-passport-card {
    background: #6F1612;
    padding: 14px 16px;
    border-radius: 10px;
}

.passport-google-detail h6 {
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 0px;
}

.passport-google-wallet img {
    margin-bottom: 10px;
}

.passport-google-details h6 {
    font-family: 'Poppins';
    font-size: 11px;
    color: #fff;
    margin-bottom: 0px;
}

.passport-google-wallet {
    text-align: center;
}

.mobi-right {
    text-align: right;
}

.passport-google-details p {
    margin-bottom: 0;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
}

.google-wallet-desktop {
    text-align: right;
}

.google-wallet-desktop img {
    width: 35%;
    margin-bottom: 10px;
}

/* flexi details */
.flexi-booking {
    border-top: 1px solid #EDE8E9;
    border-left: 1px solid #EDE8E9;
    border-right: 1px solid #EDE8E9;
    border-radius: 5px 4px 0px 0px;
    padding: 5px;
    position: relative;
    top: 0px;
}

.flexi-booking p {
    margin-bottom: 0;
    color: #000;
    font-size: 14px;
}

.refunded-view p {
    font-size: 12px;
    background: #fff;
    color: #007D23;
    padding: 3px 8px;
    border-radius: 30px;
}

.booking-details-view-flexi {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.refunded-amt {
    background: #fff;
    color: #007D23;
    padding: 3px 10px;
    border-radius: 30px;
}

.flexi-refund-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.inr-mo {
    margin-bottom: 0px !important;
}

.refunde-money {
    font-size: 12px;
    color: #1A8C2D;
    font-weight: 600;
    margin-bottom: 3px !important;
    display: block;
}

.alert-box {
    background: #F9F9F9;
    border: 1px solid #DDDDDD;
    padding: 20px;
    margin: 20px 0;
    border-radius: 10px;
}

@media (max-width: 575.98px) {
    .mx-2 {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }

    .alert-box {
        padding: 10px;
        margin: 0 10px;
    }
}

.voucher-qr-icon {
    position: absolute;
    right: 25px;
    bottom: 20px;
}

.redeem-icon {
    /* background: url('../../../assets/kotakpp/redeemed.png') no-repeat content-box center/contain; */
    background-image: url('../../../assets/kotakpp/redeemed.png');
    background-repeat: no-repeat;
    background-position: center;
    background-origin: content-box;
    background-size: 81px 56px;
}

.profile-account .enable_alert {
    font-size: 14px;
    font-weight: 700;
    line-height: 18.2px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.watch-list-mobile-box .img-arrow.toogle-checkbox {
    padding-right: 15px;
}

.watch-list-mobile-box p {
    font-size: 13px;
    font-weight: 500;
    line-height: 19.6px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.optinsubmit .addmovies-mobile,
.set-alert-not-found.optinsubmit {
    border: none;
    padding: 12px 25px;
    border-radius: 10px !important;
    font-family: 'Poppins';
    font-weight: 600;
    line-height: 14px;
    text-align: left;
}

.promotional_alert_title {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

}


.optin-optout {
    padding-top: 50px;
}

.optin-optout .profile-img.border-line-profile {
    padding: 15px 0px
}

.optin-optout .profile-img.border-line-profile:nth-child(2) {
    margin-bottom: 0px;
}

.optin-optout .addmovies-mobiles.optinsubmit {
    margin-top: 50px;
}

.optin-optout button.sc-gKsecS.dzxsDx.addmovies-mobile {
    width: 80%;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
}