.pvrTheme {
    --backgroundColor: #FFCB05;
    --bgGradient: linear-gradient(334deg, #E76F00 00%, #FFEB34 100%);
    --pColor: #000;
    --bgLight: #fff0d6;
    --modalBgColor: #FFE194;
    --modalColor: #EF761A;
    --borderColor: #FFFDF5;
    --fontWeight: 700;
    --orderStatusBG: #fff0d6;

}

/* inoxTheme */
.inoxTheme {
    --backgroundColor: #234B9E;
    --bgGradient: linear-gradient(334deg, #234B9E 00%, #D9ECDE 100%);
    --pColor: #fff;
    --bgLight: #EDF3FF;
    --modalBgColor: #234B9E;
    --modalColor: #FFF;
    --borderColor: #F5F9FF;
    --fontWeight: 600;
    --orderStatusBG: #EDF3FF;
}

/*----------------------------------------------------------*/

.Reco-card-inn {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 10px;
    width: 270px;
    border: 1px solid #E4E4E4;
    border-radius: 10px;
    color: black;
    padding: 5px 6px;
    background-color: white;
}

.Reco-item-view {
    position: relative;
}

.Reco-item-viewImg {
    height: 66px;
    width: 66px;
    object-fit: contain;
    background: #fff;
    border-radius: 10px;
    /* border: 1px solid #E4E4E4; */
    padding: 2px;
}

.fnbinout-back {
    display: flex;
    align-items: flex-start;
    /* width: 100%; */
    position: absolute;
    /* top: 6vh; */
    margin-top: 7px;
    left: 35px;
}

.Rec-outer {
    position: relative;
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    margin: 8px 10px;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: -moz-scrollbars-none;
    /* Firefox */
    -webkit-overflow-scrolling: touch;
}

.Reco-itemVorN {
    position: absolute;
    top: 5px;
    left: 5px;
    background-color: white;
    height: 12px;
    max-height: 12px;
    max-width: 12px;
}

.Reco-itemNon {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: white;
    height: 14px;
    max-height: 14px;
    max-width: 14px;
}

.fnb-itemVorN {
    position: absolute;
    top: 12px;
    left: 8px;
    background-color: white;
    height: 12px;
    max-height: 12px;
    max-width: 12px;
}

/* .fnb-itemNon {
    position: absolute;
    top: 12px;
    left: 8px;
    background-color: white;
    height: 12px;
    max-height: 17px;
    max-width: 17px;
} */

svg.m-v-nvIm {
    vertical-align: initial;
    position: relative;
    top: -1px;
}

.Reco-item-desc {}

.upgradeModale p.Reco-itemName,
.upgradeModale p.Reco-itemPrice {
    color: #000;
    font-size: 13px;
}

.Reco-itemName {
    text-align: left;
    font-weight: 600;
    font-size: 13px;
    margin-bottom: 0px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 165px;
    text-transform: capitalize;
}

.Reco-itemPrice {
    font-family: var(--fontFamilyMedium);
    font-size: 13px;
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    margin-bottom: 5px;
    line-height: 18px;
}




.fndAddBtn {
    background-color: var(--bgLight);
    border: 1px solid var(--backgroundColor);
    border-radius: 6px;
    padding: 2px 25px;
    font-size: 15px;
    font-weight: 600;
    outline: none;
    color: #000;
    /* width: 100%; */
}

.ra-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 10px;
    justify-content: center;
    background: linear-gradient(to bottom, var(--bgLight) 30%, #FFFFFF 100%);
    padding-top: 15px;
    padding-bottom: 5px;
}

.ra-ptag {
    margin: 0px;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.fnb-dis-div {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    align-items: center;
    color: black;
    background-color: white;
    padding: 10px;
    width: 270px;
    border-radius: 10px;
    min-width: 270px;
    height: 55px;
    cursor: pointer;


}

.fnb-disoff {
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    margin: 0px;
    /* white-space: nowrap; */
    overflow: hidden;
    text-overflow: ellipsis;
    /* width: 210px; */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
}

.fnb-disId {
    margin: 0px;
}

.dis-outer {
    position: relative;
    padding: 10px;
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    background-color: #EDE8E9;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: -moz-scrollbars-none;
    -webkit-overflow-scrolling: touch;

}

.dis-outer::-webkit-scrollbar {
    display: none;
}

.vchrLanding,
.mncategories {
    position: relative;
    display: flex;
    align-items: center;
}

.cmbpart {
    padding: 20px 20px 0px 0px;
}

.cmbTitle {
    font-size: 12px;
    margin-bottom: 15px;
    display: block;
    justify-content: space-between;
    line-height: 14px;
    color: #808190;
    gap: 5px;
    text-transform: capitalize;
}

.cmbTitle:last-child {
    margin-bottom: 0;
}

.cmbleft {
    display: flex;
    gap: 5px;
}

.fnb-scrollArrRight {
    right: 5px;
}

.fnb-scrollArrleft {
    left: 5px;
    transform: rotate(180deg);
}

.fnb-arrow {
    position: absolute;
    /* top: 35%; */
    z-index: 9;
    cursor: pointer;
}

.rfy-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 10px;
    justify-content: center;
    height: 40px;
    margin: 5px 0px;
}

.off-nadiv {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
    margin: 0px;
    margin-top: 15px
}

.off-nah2 {
    font-size: 20px
}

.loading-messagefnb,
.loading-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
}

.loading-section {
    height: 250px;
}

.loading-section h2 {
    font-size: 18px;
}

/* From Uiverse.io by Galahhad */
.checkboxVeg {
    display: none;
}

.checkboxNonVeg {
    display: none;
}

.fb-os-main {
    /* background-color: #f1f1f1; */
    min-height: 100vh;
    padding-bottom: 20px;
}

.sliderVeg {
    width: 40px;
    height: 18px;
    background-color: #f2ecec;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    align-items: center;
    transition: .3s;
    box-shadow: 0 0 10px 0 rgb(0, 0, 0, 0.25) inset;
    cursor: pointer;
}

.sliderVeg::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: #fff;
    transform: translateX(3px);
    border-radius: 20px;
    transition: .3s;
    box-shadow: 0 0 10px 3px rgb(0, 0, 0, 0.25);
    top: 0px;
    height: 14px;
    width: 14px;
}

.sliderNonVeg {
    width: 40px;
    height: 20px;
    background-color: #f2ecec;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    align-items: center;
    transition: .3s;
    box-shadow: 0 0 10px 0 rgb(0, 0, 0, 0.25) inset;
    cursor: pointer;
}

.sliderNonVeg::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: #fff;
    transform: translateX(3px);
    border-radius: 17px;
    transition: .3s;
    box-shadow: 0 0 10px 3px rgb(0, 0, 0, 0.25);
    top: 0px;
    height: 14px;
    width: 14px;
}

.checkboxVeg:checked~.sliderVeg::before {
    transform: translateX(24px);
    box-shadow: 0 0 10px 3px rgb(0, 0, 0, 0.25);
}

.checkboxVeg:checked~.sliderVeg {
    background-color: #409852;
}

.checkboxVeg:active~.sliderVeg::before {
    transform: translate(0);
}

.checkboxNonVeg:checked~.sliderNonVeg::before {
    transform: translateX(24px);
    box-shadow: 0 0 10px 3px rgb(0, 0, 0, 0.25);
}

.checkboxNonVeg:checked~.sliderNonVeg {
    background-color: rgb(255, 49, 49);
}

.checkboxNonVeg:active~.sliderNonVeg::before {
    transform: translate(0);
}

.fbvegDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 7px;
    border: 1px solid #EDE8E9;
    padding: 5px 10px;
    width: max-content;
    border-radius: 5px;
    cursor: pointer;
}

.hdrsearchDesk {
    border: 1px solid #D6D6D6;
    padding: 8px;
    border-radius: 50%;
    cursor: pointer;
}

.fb-filter {
    width: 100%;
    color: black;
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    margin: 10px 0px;
    border-top: 1px solid #EDE8E9;
    border-bottom: 1px solid #EDE8E9;
    padding: 8px 10px;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: -moz-scrollbars-none;
    -webkit-overflow-scrolling: touch;

    background-color: white;
    z-index: 3;
}

.food-views {
    padding: 0;
}

.fb-filter::-webkit-scrollbar {
    display: none;
}

.fb-v {
    font-size: 14px;
    margin: 0px;
    text-wrap: nowrap;
    display: flex;
    align-items: center;
    column-gap: 6px;
    text-transform: capitalize;
}

.fbvegimg {
    object-fit: contain;
    height: 18px;
}

.fb-card-inn {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    column-gap: 10px;
    width: calc(100% - 20px);
    border-radius: 10px;
    color: black;
    margin: 5px 10px;
    justify-content: space-between;
    cursor: pointer;
    /* padding-bottom: 5px; */
}

.fb-card-left {
    display: flex;
    flex-direction: row;
    column-gap: 5px;
}

.Reco-item-vImg {
    height: 66px;
    width: 66px;
    object-fit: contain;
    border: 1px solid #E4E4E4;
    border-radius: 10px;
    margin: 5px;
    max-height: 80px;
    max-width: 80px;
    margin-left: 0px;
    /* padding: 2px; */
}

.bf-itemVorN {
    position: absolute;
    top: 12px;
    left: 12px;
    background-color: white;
    height: 20px;
}

.err-osfnb {
    text-align: center;
    height: 100vh;
    display: flex;
    align-items: center;
}

.err-osfnb h2 {
    font-size: 15px;
}

.fb-itemName {
    margin-top: 5px;
    display: block;
    display: -webkit-box;
    max-width: 100%;
    /* height: 37px; */
    margin: 5px auto;
    font-size: 13px;
    line-height: 16px;
    font-weight: 600;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
}

.Customfb-btnDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5px;
    width: fit-content;
    /* margin-left: 10px; */
}

.upfb-btnDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    width: fit-content;
    justify-content: space-between;
    width: 100%;
}

.custom-btn {
    color: #7A7A7A;
    font-size: 12px;
    margin-top: 4px;
    display: block;
    /* text-align: center; */
}

.flxav {
    display: flex;
    align-items: center;
    gap: 15px;
}

.m-cus-inameDsk {
    padding: 25px;
    background-color: var(--bgLight);
    border-top: 1px solid #a6a7af;
    border-bottom: 1px solid #a6a7af;
}


.imsImg img.fnb-am-iimag {
    width: 80px;
    height: 80px;
    flex-shrink: 0;
    border: 1px solid #EBEBEB;
    border-radius: 6px;
    background: #fff;
    display: flex;
    align-items: center;
    object-fit: contain;
}

.imsImg {
    position: relative;
}

.imsImg img.vgicon {
    width: 12px;
    height: 12px;
    position: absolute;
    margin: 8px;
    border: 0;
    border-radius: 0;
}

.fb-itemPrice {
    font-family: var(--fontFamilyRegular);
    font-weight: 400;
    font-size: 15px;
    line-height: 15px;
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    margin-bottom: 5px;
    margin-top: 3px;

}

.containerfnb {
    padding: 10px;
    border-bottom: 1px solid #DDD;
    box-shadow: none;
    background-color: #fff;
    position: sticky;
    top: 0px;
    z-index: 99;
}

.cinelistHdr {
    border-radius: 15px 15px 0 0;
}

.cont-fnb-inn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: black;
    align-items: center;
}

.fnb-loc-div {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.fnb-loc-div .custom-dropdown-arrow img {
    position: relative;
    top: 3px;
}

.fnb-loc-div img {
    cursor: pointer;
}

.loction-fnb {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 5px;
    margin: 0px;
    color: #000;
}

.loction-fnbdesk {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 5px;
    margin: 0px;
    color: #000;
    margin-left: 20px;
}

.fnbloc-name {
    margin: 0px;
    font-weight: 500;
    font-size: 13px;
}


.fnb-os-p {
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    margin: 0px;
    text-transform: capitalize;
}

.fnb-os-pdesk {
    font-size: 20px;
    font-weight: 700;
    margin: 0px;
    text-transform: capitalize;
    margin-left: 20px;
}

.above-m-div {
    display: flex;
    /* flex-direction: row; */
    border-top: 1px solid #EDE8E9;
    gap: 10px;
    width: 100%;
    justify-content: space-between;
    color: black;
    padding: 20px;
}

.above-m-divdesk {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    color: black;
    row-gap: 5px;
}

.abtleftItem {
    display: flex;
    gap: 10px;
}

.m-itemName {
    margin: 0px;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    margin-left: -22px;
    display: flex;
    column-gap: 7px;
    text-transform: capitalize;
}

.qty-fb-div {
    display: flex;
    flex-direction: row;
    margin: 0px;
    color: #7A7A7A;
    width: 60vw;
    margin-top: 10px;
    line-height: 10px;

}

.m-fb-desc {
    position: relative;
    margin: 5px 0px;
    color: #7A7A7A;
    font-size: 11px;
    /* max-width: 200px; */
    line-height: 11px;
    /* margin-top: 7px; */
    display: flex;
    align-items: center;
}

.m-fb-desc li:first-child {
    list-style: none;
}

.m-fb-desc li {
    padding-right: 25px;
}

.m-fb-price {
    font-size: 16px;
    font-family: var(--fontFamilyBold);
    font-weight: 600;
}

.m-qty {
    margin: 0px;
    font-size: 13px;
}

.m-Kcal {
    margin: 0px;
    font-size: 13px;
}

.m-v-nvImg {
    height: 15px;
    margin-bottom: 8px;
}

.m-offer-main {
    color: black;
    width: 100%;
    padding: 15px 0px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    width: 100vw;
    border: none;

}

.m-offer-maindesk {
    width: 375px;
    height: 100vh;
    color: black;
    padding-bottom: 15px;
}

.m-offer-hdiv {
    font-size: 18px;
    font-weight: 600;
    /* border-bottom: 1px solid #EDE8E9; */
    margin: 0px 15px;
    padding: 30px 10px;
}

.m-offer-head {
    font-size: 20px;
    font-weight: 600;
}

.m-offer-ptag {
    color: #7A7A7A;
    font-size: 13px;
    margin-bottom: 0;
}

.m-offer-dec-div {
    padding: 2px 15px;
    /* scrollbar-width: none; */
    overflow: auto;
    height: 85vh;
    overflow: -moz-scrollbars-none;
    -webkit-overflow-scrolling: touch;
}

.m-offer-dec-div::-webkit-scrollbar {
    width: 5px;
    /* height: 10px; */
}

.m-offer-dec-div::-webkit-scrollbar-track {
    background-color: #c6c6c6;
}

.m-off-dec-h {
    font-weight: 600;
    font-size: 17px;
    margin-top: 20px;
}

.m-off-dec-li {
    /* margin-left: 12px; */
    font-size: 12px;
    color: #7A7A7A;
    margin-bottom: 5px;
}

.fb-c-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 2px;
    height: 28px;
    background-color: var(--backgroundColor);
    border-radius: 5px;
    width: 82px;
    color: var(--pColor);
}

.chkoutbtn .fb-c-div {
    margin-left: 5px;
    width: 65px;
}

.fb-c-div img {
    height: 17px;
    width: 17px;
}


.fb-c-btn {
    background-color: transparent;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    outline: none;
    border: none;
}

.fb-check-span {
    font-weight: 600;
    font-size: 14px;
    width: 70px;
    text-align: center;
    margin: 0px;
}

.fb-c-span {
    font-weight: 600;
    font-size: 14px;
    width: 25px;
    text-align: center;
    margin: 0px;
}

.cartV-Nveg {
    position: absolute;
    left: 7px;
    height: 13px;
    width: 13px;
    bottom: 7px;
    object-fit: contain;
    background-color: white;
}

.cartV-Nvegdesk {
    position: absolute;
    left: 6px;
    height: 13px;
    width: 13px;
    top: 6px;
    object-fit: contain;
    background-color: white;
}

.fnb-c-addons {
    font-size: 13px;
    font-weight: 500;
}

.cart-addonP {
    font-size: 12px;
    line-height: 10px;
    margin-bottom: 5px;
    text-transform: capitalize;
}

.fb-added-div {
    position: fixed;
    bottom: 0px;
    padding: 6px 0px;
    text-align: center;
    background-color: #f8f6f6;
    width: 100%;
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}

.fb-added-divdesk {
    position: sticky;
    bottom: 0px;
    padding: 6px 0px;
    text-align: center;
    background-color: white;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    z-index: 9;
}

.prcfont {
    font-family: var(--fontFamilyBold);
}

.fb-added-btn {
    /* font-family: var(--fontFamilyBold); */
    font-size: 16px;
    background-color: var(--backgroundColor);
    color: var(--pColor);
    padding: 10px 30px;
    border-radius: 8px;
    outline: none;
    border: none;
    width: auto;
    font-weight: 600;
    /* float: right; */
    margin: 0 20px 15px;
}

.chkbtn {
    text-align: right;
}

.fb-added-btndesk {
    font-family: var(--fontFamilyBold);
    font-size: 16px;
    background-color: var(--backgroundColor);
    color: var(--pColor);
    padding: 10px 30px;
    border-radius: 8px;
    outline: none;
    border: none;
    width: 30%;
    font-weight: 600;
    float: right;
    margin: 0 20px 0px;
}

.fb-added-btn.addcrtbtn {
    width: 100%;
    margin: 0;
}

.fb-added-div img {
    vertical-align: top;
}

.m-cus-main {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #000;
}

.m-cus-maindesk {
    display: flex;
    width: 375px;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    color: #000;
    height: 100vh;
}

.thumbCloseModal {
    display: none;
    position: absolute;
    top: 10px;
    left: 47%;
    padding: 10px 0px;
}

.thumbCloseModalOff {
    display: none;
    position: absolute;
    top: 3px;
    left: 47%;
    padding: 10px 0px;
}


.fnbdeskdiv {
    width: 100%;
    background-color: #f1f1f1;
    min-height: calc(100vh - 210px);
    padding: 20px 0px;
    /* align-items: center;
    justify-content: center;
    height: 100vh;
    overflow: auto; */
}

.topnotedc {
    font-size: 15px;
    margin: 15px 10px 30px;
    font-weight: 500;
}

.notedc {
    text-align: left;
    font-size: 13px;
    font-weight: 600;
    margin: 0px 10px 20px;
}

.fnbdeskdiv-inn {
    position: relative;
    max-width: 750px;
    width: 100%;
    background-color: white;
    margin: 30px auto;
    border: 1px solid #DDD;
    border-radius: 15px;
}

.aboutfnbcloseOut {
    display: flex;
    justify-content: flex-end;
    position: relative;
    cursor: pointer;
}

.aboutfnbclose {
    height: 17px;
    top: 13px;
    text-align: right;
    right: 13px;
    position: absolute;
}

.aboutfnb-main {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    /* padding: 15px 20px; */
    padding: 40px;
    column-gap: 40px;
}


.abtmodalDsk .fb-c-div {
    width: 100%;
    margin-bottom: 10px;
    padding: 0;
    border: 1px solid var(--backgroundColor);
    background-color: transparent;
    color: #000;
    height: auto;
}

.abtmodalDsk .fb-c-span {
    width: 100%;
}

.abtmodalDsk .fndAddBtn {
    padding: 5px 25px;
}

.abtmodalDsk .fb-c-btn {
    background-color: var(--backgroundColor);
    border-radius: 0;
    flex-shrink: 0;
    width: 30px;
    height: 30px;
}

.abtmodalDsk .fb-c-btn img {
    height: 22px;
    width: 22px;
}

.m-cus-head {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    padding: 30px 4%;
    border-bottom: 1px solid #EDE8E9;
    position: sticky;
    top: 0px;
    z-index: 9;
    background-color: white;
    /* margin-top: 0px; */
}

.upgradeModale .m-cus-head {
    padding: 15px 4% 0;
    border-bottom: 0px solid #EDE8E9;
    background-color: transparent;
}

.m-cus-head img {
    cursor: pointer;

}

.m-cus-inn {
    display: flex;
    width: 100%;
    flex-direction: column;
    max-height: calc(100vh - 190px);
    overflow: auto;
}

.Cus-itemspan {
    font-size: 18px;
    line-height: 18px;
    font-weight: 600;
    /* width: 83vw; */
}

.offerDetailsDsk {
    display: flex;
    gap: 15px;
    align-items: center;
    padding: 15px;
    background: var(--bgLight);
    border-top: 1px solid #D6D6D6;
    border-bottom: 1px solid #D6D6D6;
}

.m-cus-iname {
    color: #444444;
    font-size: 15px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 10px 0px;
}

.m-extraI {
    margin: 10px auto;
    width: 90%;
}

.m-extraH {
    font-size: 13px;
    border-bottom: 1px solid hsl(236deg 7% 53% / 40%);
    padding-bottom: 5px;
    color: #007D23;
    font-weight: 600;
}

.m-cus-ip {
    margin: 0px;
    color: #444444;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    overflow: hidden;
    /* white-space: nowrap; */
    /* text-overflow: ellipsis; */
    width: 100%;
    text-transform: capitalize;
}

.m-cus-iprice {
    font-size: 14px;
    font-family: var(--fontFamilyBold);
    margin: 0px;
    font-weight: 600;
    color: #444;
}

.itmsDsk .custom-btn {
    display: flex;
    align-items: center;
    margin-top: 0;
    font-size: 15px;
    color: #000;
    font-weight: 500;
}

.itmsDsk .custom-btn::before {
    content: "";
    width: 5px;
    height: 5px;
    background-color: #7A7A7A;
    position: relative;
    left: -7px;
    border-radius: 50%;
}

.m-e-iList {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    color: #000;
    margin: 10px 0;
}

.m-e-iName {
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 600;
    color: #000;
    margin: 0;
}

.m-e-iprice {
    font-size: 14px;
    font-family: var(--fontFamilyRegular);
    font-weight: 600;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 0;
}

.m-e-iprice>* {
    cursor: pointer;
    color: #444;
}

.m-e-icheck {
    margin-left: 5px;
}

.m-e-icheck[type="checkbox"] {
    width: 16px;
    height: 16px;
    accent-color: var(--backgroundColor);
    border-radius: 10px;
    color: white;
    padding: 10px;
    margin-top: 1px;
}

.m-e-icheck:focus-within {
    accent-color: var(--backgroundColor);
}

.m-c-final {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 -2px 4px hsl(0deg 0% 0% / 10%);
}

.m-c-finalPrice {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    /* margin: 5px 0px; */
}

.c-btn-pro {
    background-color: var(--backgroundColor);
    font-weight: 600;
    font-size: 16px;
    padding: 12px 0px;
    border: none;
    outline: none;
    border-radius: 10px;
    color: var(--pColor);
}

.m-c-total {
    font-family: var(--fontFamilyBold);
    font-size: 14px;
    font-weight: 600;
}

.fb-save-label {
    background-color: #d9ecde;
    color: #007D23;
    font-size: 14px;
    text-align: center;
    margin-top: -20px;
    margin-bottom: 20px;
}

.fb-save-lP {
    font-family: var(--fontFamilyMedium);
    padding: 10px 0px;
    font-weight: 600;
    margin-bottom: 0;
}

.buyItem-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin: 6px 0px;
}

.buyItem-listInn {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.fb-checkout-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 2px;
    height: 30px;
    background-color: transparent;
    border-radius: 5px;
    width: 70px;
    /* border: 1px solid #EDE8E9; */
}

.upgradeModale .up-card-inn {
    width: 100%;
    background-image: none;
}

.upgradeModale .ys-ptag {
    font-size: 18px;
    margin: 15px 0;
    color: #000;
}

.foodqnty {
    max-height: 35vh;
    width: 100%;
    overflow: auto;
}

.upgradeModale .dtmsDiv {
    padding: 15px;
}

.upgradeTag {
    font-size: 12px;
    color: #888;
}

.mintitle {
    font-size: 16px;
    text-transform: uppercase;
    color: #888888;
    font-weight: 600;
    margin: 25px 0 10px;
}

.upgradeModale .Reco-item-viewImg {
    height: 50px;
    width: 50px;
}

.flxcnf {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px;
    padding: 20px 0;
}

.co-itemName {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    column-gap: 8px;
}

.co-itemName.chkoutbtn {
    align-items: center;
}

.co-itemNameP {
    margin: 0px;
    font-weight: 500;
    font-size: 15px;
    width: 100%;
    margin-right: 5px;
    display: -webkit-box;
    max-width: 100%;
    /* height: 34px; */
    line-height: 17px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-weight: 500;
    text-overflow: ellipsis;
    text-transform: capitalize;
}

.co-pricep {
    font-family: var(--fontFamilyMedium);
    font-size: 15px;
    font-weight: 600;
    margin: 0px;
    line-height: 17px;
    text-align: right;
    width: 80px;
    text-wrap: nowrap;
}

.co-vNv {
    height: 18px;
    flex-shrink: 0;
    /* margin-top: 3px; */
}

.co-addon-div {
    font-size: 12px;
    text-align: left;
    width: 100%;
    /* display: flex; */
    justify-content: center;
    align-items: center;
}

.co-outer {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    margin: 0 auto;
    width: 95%;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    background-color: #FFF;
}

.co-ra-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 10px;
    justify-content: center;
    padding-top: 15px;
    padding-bottom: 12px;
}

.co-outer-maindiv {
    background-color: #FFF;
    padding-top: 20px;
    padding-bottom: 20px;
    /* min-height: 103vh;
    padding-top: 1px; */
}

.co-ra-ptag {
    margin: 0px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #888888;
}

.fb-a-offer {
    background-color: var(--borderColor);
    width: 95%;
    margin: 10px auto;
    border: 1px solid #E4E4E4;
    border-radius: 10px;
    color: black;
    align-items: center;

}

.fb-a-a {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    padding-top: 12px;
    margin: 0px;
    cursor: pointer;
}

.fb-a-ap {
    margin: 0px;
    font-size: 16px;
    font-weight: 600;
}

.tra-dic-co {
    height: 25px;
    margin-right: 10px;
    vertical-align: middle;
}

.co-savepop {
    background-color: #D9ECDE;
    color: #007D23;
    display: flex;
    flex-direction: row;
    margin: 0px 2.5%;
    padding: 8px 4%;
    border-radius: 10px;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 10px;
    align-items: center;
}

.co-savepopP {
    font-family: var(--fontFamilyRegular);
    margin: 0px;
    font-size: 13px;
    line-height: 13px;
}

.co-savepop img {
    height: 15px;
    width: 15px;
}

.co-dassImg {
    display: block;
    width: 100%;
    margin-bottom: 10px;
}

.dts-main-div {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.dts-inn-div {
    display: flex;
    /* flex-direction: column; */
    gap: 15px;
    background-color: white;
    border: 1px solid #E4E4E4;
    border-radius: 10px;
    width: 95%;
    padding: 10px 10px;
    margin-top: 10px;
}

.dtmsDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* flex-wrap: wrap; */
    width: 100%;
    background-color: #F9F9F9;
    margin: 5px 0;
    border-radius: 7px;
    padding: 10px 15px;
    border: 1px solid transparent;
    cursor: pointer;
}

/* .seatntaval {
    display: none;
    filter: opacity(0.5);
    pointer-events: none;
} */

.tagCount {
    position: absolute;
    top: 0;
    right: 0;
    background: #FFCB05;
    padding: 1px 10px;
    font-size: 13px;
    margin: 0;
    border-radius: 0 5px;
    font-weight: 600;
    color: #000;
}

.rdactive {
    border: 1px solid var(--backgroundColor);
    background-color: var(--bgLight);
}

.confirmSeat {
    width: 100%;
    margin: 0;
    font-size: 14px;
    line-height: 14px;
    font-weight: 700;
    padding-top: 5px;
}

.centerModal p.dtmsP,
.dtmsP {
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
    margin: 0;
    color: #000;
    text-transform: capitalize;
    /* padding: 8px 0; */
}

.rdochk {
    border: 2px solid #888888;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rdactive .rdochk {
    border-color: var(--pColor);
}

.rdochk img {
    width: 100%;
    height: 100%;
}

.dtmsradio {
    border: none;
}

.dtmsradio[type='radio'] {
    height: 20px;
    width: 20px;
    accent-color: #007D23;
}

.fb-stdiv {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    /* margin-bottom: 65px; */

}

.fb-a-aLink {
    color: #000;
    font-weight: 600;
    margin: 0px;
}

.fb-stdivInn {
    display: flex;
    flex-direction: column;
    width: 95%;
    background-color: white;
    border: 1px solid #E4E4E4;
    border-radius: 10px;
    padding: 10px;
    color: #000;
}

.fb-stdivI {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.fb-stdivIII {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
}

.fb-stdivIIIII {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* border-top: 1px solid #E4E4E4; */
    /* padding-top: 10px; */
}

.offer-cardOuter {
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%; */
    margin: 20px 0px;
}

.fb-o-card {
    /* width: calc(100% - 20px); */
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.fb-o-cardInn {
    background-color: white;
    border: 5px solid #DDEEE0;
    border-left: 20px solid #DDEEE0;
    z-index: 1;
    position: relative;
    color: black;
    padding: 15px;
    border-radius: 10px;
    height: -webkit-fill-available;
    margin: 5px 0px;
    height: auto;
}

.fb-o-cardInn:before {
    content: '';
    width: 10px;
    height: calc(93% - 15px);
    background-image: radial-gradient(circle, #ffffff 50%, #ffffff00 50%);
    background-size: 10px 15px;
    position: absolute;
    top: -5px;
    left: -24px;
    margin: 15px 0;
}

.bankTag {
    font-size: 12px;
    line-height: 12px;
    font-weight: 600;
    background-color: #DDEEE0;
    color: #146922;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
    padding: 2px 5px 4px 5px;
    position: absolute;
    right: 0;
    top: 0;
    border-bottom-left-radius: 7px;
}


.fb-o-cardInn.bankstatus {
    background-color: white;
    border: 5px solid #FFF0D6;
    border-left: 20px solid #FFF0D6;
    height: auto;
}

.bankstatus .bankTag {
    background-color: #FFF0D6;
    color: #BF9804;

}

.fb-o-hdr {
    border-bottom: 1px dashed #e8e8e8;
    padding-bottom: 5px;
    margin-bottom: 5px;
}

.fb-o-abs {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.fb-o-absImg {
    height: 100%;
    width: 100%;
    min-width: 300px;

}

.dassed-card {
    width: 100%;
}

.o-off {
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    margin-bottom: 3px;
    margin-top: 8px;
}

.off-vaild {
    color: #7A7A7A;
    font-size: 12px;
    margin: 0px;
    overflow: hidden;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.fboffer-more {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
}

.moreLink {
    color: black;
    font-weight: 600;
    font-size: 13px;
    background: transparent;
    border: 0;
}

.o-applyBtn {
    background-color: var(--backgroundColor);
    color: var(--pColor);
    font-weight: var(--fontWeight);
    font-size: 12px;
    border: none;
    outline: none;
    border-radius: 5px;
    padding: 6px 10px;
}

.fb-o-m {
    padding: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #000;
    text-align: center;
}

.fb-o-emoji {
    position: absolute;
    top: -30px;
}

.fb-m-code {
    margin-top: 20px;
    font-size: 15px;
    font-weight: 500;
    text-transform: capitalize;
}

.fb-m-det {
    margin: 10px 0px;
}

.fb-m-price {
    font-family: var(--fontFamilyBold);
    font-size: 18px;
    font-weight: 600;
    margin: 0px;
}

.fb-m-saved {
    font-size: 18px;
    font-weight: 500;
    margin: 0px;
}


.fnbTitle {
    font-size: 24px;
    line-height: 24px;
    font-weight: 600;
    text-align: center;
    padding: 20px;
    border-bottom: 1px solid #DDDDDD;
    color: #000;
}

.outer-fbOption {
    margin: 40px 15%;
}

.secDisabled {
    pointer-events: none;
    opacity: 0.5;
}

.fb-m-offP {
    margin: 0px;
    font-size: 18px;
    font-weight: 500;
}

.fb-m-aBtn {
    background-color: var(--backgroundColor);
    color: var(--pColor);
    font-size: 15px;
    border: none;
    outline: none;
    border-radius: 10px;
    padding: 12px 80px;
    font-weight: 700;
    margin-top: 20px;
    color: var(--pColor);
}

.fb-ptitle {
    width: 100%;
}

.fb-op-card {
    margin: 20px 0px;
}

.fb-op-cardInn {
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    height: 130px;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
    padding: 4px;
    gap: 30px;
}

.fb-o-imgdiv {
    background-color: var(--bgLight);
    height: 100%;
    width: 50%;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fb-o-qr-p {
    text-align: left;
    width: 100%;
    font-weight: 700;
    font-size: 22px;
    margin: 0px;
    color: black;
}

.outer-fbOption {
    margin: 40px 20%;
}

.outer-fbOption.lgnfrst {
    filter: grayscale(1);
    pointer-events: none;
}

.fb-prg {
    width: 100%;
    font-size: 14px;
    line-height: 14px;
    margin-top: 10px;
    margin-bottom: 0;
}

.fb-cinemalist {
    background-color: #EDE8E9;
    padding: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fb-searchI {
    border: none;
    width: 85%;
    padding: 10px 15px;
    border-radius: 30px;
}

.fnbmdl.modal.show .modal-dialog {
    margin: 0px;
    position: absolute;
    bottom: 0px;
    right: 0;
}

.fnbmdl.modal .modal-dialogdesk,
.fnbmdl.modal.show .modal-dialog {
    right: -500px;
    animation: slideInRight 0.3s backwards;
}

.fnbmdl.modal.show .modal-dialogdesk {
    margin: 0px;
    position: absolute;
    top: 0px;
    right: 0;
    transition: 0.3s all ease-in-out;
}

.fnbmdl.modal.show .modal-dialogdeskAbout {
    margin: 0px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    bottom: unset;
    animation-name: none;
    animation-duration: 0s;
    max-width: 550px;
}

.spinner-border {
    --bs-spinner-border-width: 3px;
}

@keyframes slideInRight {
    from {
        transform: translateX(100%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

.fnbmdl .modal-content {
    border-radius: 0;
}

.fnbmdl .modal-dialogdeskAbout .modal-content {
    border-radius: 15px;
}

.food-views .foods-layout {
    padding: 0 !important;
}

.profile-complete,
.profile-complection {
    display: none !important;
}

.Personal-show-static span:last-child {
    word-break: break-word;
    margin-left: 15px;
}

/* Modal dialog animation */

@media (max-width:1024px) {
    .fnbmdl .modal-dialog {
        animation: slideInUp 0.3s backwards;
    }

    .fnbmdl .modal-content {
        width: 100vw;
        border-radius: 25px 25px 0 0;
        max-height: 90vh;
        overflow: auto;
    }

    .fnbmdl.modal.show .modal-dialog {
        width: 100%;
    }

    .fnbmdl.modal.fade .modal-dialog {
        animation: slideInUp 0.3s backwards;
    }

    .fnbmdl.modal.fade .modal-dialog.modal-hide {
        animation: slideOutDown 0.3s forwards;
    }

    .fnbmdl.modal .modal-dialogdesk,
    .fnbmdl.modal.show .modal-dialog {
        right: unset;
        animation: slideInUp 0.3s backwards;
    }


    .cmbTitle {
        display: flex;
    }

    .cmbprice {
        padding-left: 0;
    }

    .above-m-div {
        padding: 0 20px;
        border: 0;
    }

    .Cus-itemspan {
        font-size: 14px;
        line-height: 14px
    }


    .m-cus-head.custItms {
        width: 90%;
        padding: 30px 0 10px;
        border-bottom: 1px solid hsl(236deg 7% 53% / 40%);
    }

    .cmbpart {
        padding: 0 20px 0px 45px;
    }


    /* Keyframes for sliding up from the bottom */
    @keyframes slideInUp {
        from {
            transform: translateY(100%);
            opacity: 0;
        }

        to {
            transform: translateY(0);
            opacity: 1;
        }
    }

    @keyframes slideOutDown {
        from {
            transform: translateY(0);
            opacity: 1;
        }

        to {
            transform: translateY(100%);
            opacity: 0;
        }
    }
}


@media (max-width:575px) {
    .food-views .foods-layout {
        height: auto !important;
        overflow: unset;
    }
}

/* .fb-searchI::placeholder {
    font-size: 14px;
} */

.clist-Card {
    display: flex;
    flex-direction: column;
    width: 85%;
    border: 2px solid #EDE8E9;
    margin: 5px 0px;
    padding: 10px;
    border-radius: 6px;
}

.clist-CardOuter {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
}

.clist-Cnamedis {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0px;
    width: 100%;
}

.fb-c-name {
    font-weight: 600;
    font-size: 16px;
    margin: 0px;
    color: #000;
}

.fn-c-add {
    margin-top: 10px;
    width: 80%;
}

.fn-c-addP {
    color: #959595;
    font-size: 13px;
    line-height: 20px;
    margin: 0px;
}

.fb-en-m {
    display: flex;
    padding: 25px 20px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.fb-en-mImg {
    height: 70%;
    width: 70%;
}

.fb-enP1 {
    text-align: center;
    font-weight: 600;
    margin-top: 10px;
    line-height: 20px;
}

.fb-enP2 {
    text-align: center;
    color: #6C6C6C;
    font-size: 13px;
}

.fb-en-btn {
    background-color: var(--backgroundColor);
    font-size: 15px;
    font-weight: 600;
    padding: 10px;
    border-radius: 10px;
    border: none;
    outline: none;
    width: 100%;
}

.up-card-inn {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 10px;
    width: 270px;
    border-radius: 6px;
    color: black;
    padding: 5px 10px;
    background-color: #fff0d7;
    background-image: repeating-linear-gradient(-55deg, var(--backgroundColor), var(--backgroundColor) 5px, transparent 5px, transparent 8px, #ffcb05 8px), repeating-linear-gradient(35deg, #ffcb05, #ffcb05 5px, transparent 5px, transparent 8px, #ffcb05 8px), repeating-linear-gradient(125deg, #ffcb05, #ffcb05 5px, transparent 5px, transparent 8px, #ffcb05 8px), repeating-linear-gradient(215deg, #ffcb05, #ffcb05 5px, transparent 5px, transparent 8px, #ffcb05 8px);
    background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
    background-position: 0 0, 0 0, 100% 0, 0 100%;
    background-repeat: no-repeat;
}

.fb-stp {
    font-family: var(--fontFamilyMedium);
    font-size: 14px;
    font-weight: 500;
}

.fb-stpExp {
    font-family: var(--fontFamilyMedium);
    font-size: 12px;
    font-weight: 500;
    color: #888;
    margin: 0px 0px;
    margin-bottom: 13px;
    line-height: 15px;
}

.fb-stpIII {
    /* font-family: var(--fontFamilyBold); */
    font-size: 16px;
    font-weight: 600;
    margin: 0px;
}

.fb-stpII {
    margin: 0px;
    font-size: 12px;
    color: #007D23;
}

.fb-stpII2 {
    font-family: var(--fontFamilyMedium);
    margin: 0px;
    font-size: 12px;
    color: #007D23;
    font-weight: 500;
}

.fb-stdiv4 {
    font-size: 16px;
    font-weight: 600;
    /* margin: 10px 0; */
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.ys-main-sinfo {
    width: 100%;
}

.ys-m-main-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.ys-m-inn {
    width: 90%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.swiper-slide.ys-sinfo {
    background-color: var(--bgLight);
    border: 1px solid var(--backgroundColor) !important;
    border-radius: 10px !important;
    padding: 10px;
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */
    /* width: 90%; */
    margin-bottom: 20px !important;
}

.ys-sbox {
    background-color: white;
    padding: 20px 10px;
    text-align: center;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    width: 100%;
}

.ys-seatSquMain {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100px;
}

.ys-seatSqu {
    position: relative;
    margin-bottom: 10px;
    display: flex;
    /* grid-template-columns: repeat(5, 40px); */
    gap: 10px;
    justify-content: center;
    width: 100%;
}


.ys-seatcode {
    font-size: 12px;
    line-height: 12px;
    border: 1px solid #808190;
    border-radius: 7px;
    padding: 12px 5px;
    font-weight: 600;
    width: 40px;
    margin-bottom: 0;
    cursor: pointer;
}

.ys-seatcode.selected {
    background-color: var(--backgroundColor);
    color: var(--pColor);
    border: 1px solid var(--backgroundColor);
}

.ys-con-btn {
    background-color: var(--backgroundColor);
    color: var(--pColor);
    padding: 12px 0px;
    width: 90%;
    margin-bottom: 15px;
    outline: none;
    border: none;
    border-radius: 10px;
    font-weight: var(--fontWeight);
    font-size: 15px;
}

.ys-seatSqu .errmsg {
    width: 100%;
    position: absolute;
    bottom: -15px;
}

.ys-seatImg {
    margin-top: 15px;
}

.ys-ptag {
    font-size: 18px;
    font-weight: 700;
    margin: 0px;
    color: #000;
}

.ys-pdesc {
    font-size: 13px;
    color: #000;
    text-align: center;
    margin-bottom: 30px;
    font-weight: 400;
    line-height: 20px;
}

.ys-pdesc b {
    font-weight: 600;
}

.ys-seatname {
    font-size: 16px;
    line-height: 16px;
    font-weight: 600;
    margin-bottom: 15px;
}

.ys-moviename {
    font-size: 20px;
    font-weight: 700;
    margin: 10px 0px;
}

.ys-cineAdd {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 10px;
}

.ys-cineTime {
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    margin: 0px;
    margin-bottom: 15px;
}

.ys-bdesc {
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;
    margin: 0px;
}

.m-snf-div {
    background-color: #F8F6F6;
    border: 1px solid #e3e3e3;
    border-radius: 10px;
    padding: 60px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    flex-direction: column;
}

.snf-oops {
    font-weight: 500;
    font-size: 16px;
    margin: 20px 0px;
}

.snf-desc {
    color: #6C6C6C;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    margin: 0px 10vw;
}

.snf-manualBtn {
    margin: 25px 0 30px;
    font-size: 16px;
    font-weight: 600;
    /* text-decoration: 2px underline black;
    text-underline-offset: 10px; */
    color: black;
    padding-bottom: 2px;
    border-bottom: 2px solid black;
}

.sd-cin-addOut {
    background-color: white;
    padding: 10px 0px;
    margin-bottom: 10px;
}

.sd-cin-add {
    margin: 10px 5vw;
    background-color: #EDEDED;
    border-radius: 10px;
    padding: 15px 20px;
}

.sd-cin-head {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px;
}


.rgtsec {
    display: flex;
    align-items: center;
    gap: 15px;
}

.rgtsec .search-icon-m img {
    width: 42px;
    margin-left: 0;
}

.sd-cin-addp {
    /* margin: 0px; */
    font-size: 13px;
    line-height: 17px;
    font-weight: 500;
    /* width: 80%; */
}

.sd-cin-maindiv {
    background-color: #F8F6F6;
    margin-bottom: 65px;
}

.sb-movielist {
    background-color: white;
    margin: 10px 0px;
}

.sb-movielistInn {
    padding: 15px 0px 15px 15px;
}

.sb-movieh {
    font-size: 16px;
    line-height: 16px;
    font-weight: 600;
    margin-bottom: 8px;
}

.sb-listofMov {
    display: flex;
    overflow: auto;
    column-gap: 10px;
    flex-wrap: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: -moz-scrollbars-none;
    -webkit-overflow-scrolling: touch;
}

.sb-listofMov::-webkit-scrollbar {
    display: none;
}

.sb-listofBtn {
    font-size: 14px;
    padding: 8px 25px;
    font-weight: 500;
    outline: none;
    border: 1px solid #EDE8E9;
    background-color: #F9F9F9;
    border-radius: 30px;
    text-wrap: nowrap;
    color: black;
}

.sb-listofBtn.errorFocus {
    border: 1px solid #eb0000;
    background-color: #ffd9d9;

}

.sb-listofBtn.active,
.sb-listofBtn:focus {
    border: 1px solid var(--backgroundColor);
    background-color: var(--bgLight);
}

.dtms-btn {
    width: 90%;
    background-color: var(--backgroundColor);
    color: var(--pColor);
    font-weight: 600;
    font-size: 16px;
    padding: 13px 0px;
    outline: none;
    border: none;
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 10px;
    position: fixed;
    bottom: 5px;
    margin: 5px 5vw;
}

.ss-mdiv {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* padding: 20px 0px; */
    align-items: center;
    justify-content: center;

}

.ss-srp {
    font-weight: 600;
    text-align: center;
    font-size: 17px;
    /* border-bottom: 1px solid #EDE8E9; */
    /* padding-bottom: 15px; */
    width: 100%;
    margin: 0px;
}

.fetchAutoCM {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    column-gap: 20px;
}

.ss-choose-seat {
    display: flex;
    justify-content: space-between;
    width: 90%;
    align-items: center;
    flex-direction: row;
    border-bottom: 1px solid #EDE8E9;
    padding: 15px 0px;
    cursor: pointer;
}

.ss-sname {
    font-weight: 600;
    font-size: 16px;
    margin: 0px;
}

.or-maindiv {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    flex-direction: column;
}

.or-ptag {
    font-size: 20px;
    font-weight: 700;
    margin-top: 30px;
}

.fb-os-inn {
    max-width: 650px;
    width: 90%;
    margin: auto;
    margin-bottom: 10px;
}

.fb-os-h {
    border-bottom: 1px solid #EDE8E9;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 15px 5%;
    position: sticky;
    left: 0px;
    top: 0px;
    background-color: var(--bgLight);
    z-index: 8;
}

.fb-os-ptag {
    margin: 0px;
    font-weight: 600;
    font-size: 18px;
}

.fb-os-coIDiv {
    background-color: white;
    height: 250px;
    margin: 10px 0px;
    border-radius: 15px;
    border: 1px solid #E5E5E5;
}

.fb-os-coImg {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.fb-os-about {
    background-color: white;
    border-radius: 20px;
    padding: 10px 0px;
    /* min-height: 50vh; */
    margin-bottom: 10px;
    border: 1px solid #E5E5E5;
}

.fb-os-oi {
    font-size: 15px;
    text-align: center;
    margin-bottom: 5px;
    width: 100%;
}

.fb-os-oi span {
    font-weight: 600;
}

.fb-os-ref {
    background-color: var(--orderStatusBG);
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    align-items: center;
    justify-content: center;
    width: calc(100% - 20px);
    padding: 10px 0px;
    border-radius: 5px;
    margin: 0px 10px;
}


.fb-os-ref p {
    margin: 0px;
    font-weight: 600;
    font-size: 15px;
}

.fb-os-seat {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 10px 0px;
    padding: 0px 2.3%;
}

.preparTime {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: #409852;
    color: white;
}

.fdDisc {
    width: 100%;
}

.fb-os-pt-img {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 10px;
    padding: 10px 10px;

}

.rem-time {
    background-color: #117D44;
    width: 25%;
    padding: 10px 10px;
    border-top-right-radius: 5px;
    border-top-right-radius: 5px;
    text-align: center;
}

.rem-time p {
    margin: 0px;
}

.fb-pyo {
    margin: 0px;
    font-size: 15px;
}

.vertical-stepper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.step {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
}

.Fnbstep-icon {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
}

.modalLine-div {
    margin: 5px auto;
    display: block;
}

.modalLine-img {
    width: 35px;
    padding: 7px 0px;
}

.Fnbstep-icon img {
    width: 100%;
    height: 100%;
}

.step-label {
    font-size: 15px;
    font-weight: 500;
}

.step-times p {
    font-size: 14px;
    margin-bottom: 25px;
    text-align: right;
}

.step:not(:last-child)::after {
    content: '';
    position: absolute;
    left: 11px;
    top: 24px;
    bottom: -20px;
    width: 2px;
    background-color: #808190;
}

.step.active:not(:last-child)::after {
    background-color: #000;
}


.pyo-inndiv {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    /* width: 100%; */
    background-color: #E2F4E5;
    align-items: center;
    padding: 7px 5px;
    border-radius: 5px;
    gap: 10px;
    flex-shrink: 0;
}


.pyo-maindiv {
    display: flex;
    align-items: center;
    position: sticky;
    left: 0;
    right: 0;
    bottom: 67px;
    /* padding: 0 15px; */
    margin: 0 20% 20px;
    margin-bottom: 15px;
    /* overflow: auto; */
    width: 60%;
}

.pyo-maindivInr {
    width: 100%;
    flex-shrink: 0;
}

.pyo-maindiv .swiper-slide {
    border: none !important;
}

.step.completed .step-label {
    color: #000;
}

.pyo-ptag {
    text-align: left;
    margin: 0px;
    font-weight: 600;
    font-size: 13px;
    line-height: 13px;
}

.fbitms {
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 0;
    margin-top: 5px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    overflow: hidden;
    -webkit-box-orient: vertical;
}

.pyo-ptag img {
    margin-right: 5px;
    margin-bottom: 2px;

    height: 23px;
    object-fit: contain;
}

.step.current .step-label {
    color: #000;
}

.step.pending .step-label {
    color: #ccc;
}

.pyo-btn {
    background-color: #007D23;
    color: white;
    font-size: 13px;
    font-weight: 500;
    padding: 7px 12px;
    border-radius: 30px;
    border: none;
    outline: none;
    flex-shrink: 0;
}

.step.completed:not(:last-child)::after,
.step.current:not(:last-child)::after {
    background-color: #4CAF50;
}

.ste-fb-dyn {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin: 20px 0px;
    padding: 0px 15px;
    border-bottom: 1px solid #DDDDDD;
}

.fb-os-audi {
    margin: 0px;
    font-size: 14px;
}

.fb-os-pickup {
    margin: 0px;
    font-size: 13px;
}

.fb-os-pickup span {
    font-weight: 600;
    font-size: 13px;
}

.fb-os-audi span {
    font-weight: 600;
    font-size: 14px;
}

.fb-os-item {
    display: flex;
    flex-direction: column;
    padding: 0px 15px;
}

.fb-os-i-outer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
    position: relative;
}

.fb-os-i-inn {
    display: flex;
    flex-direction: row;
    column-gap: 7px;
    align-items: center;
}

.fb-os-i-innstatus {
    display: flex;
    flex-direction: row;
    column-gap: 7px;
    align-items: flex-start;
}

.fb-os-i-innstatus img {
    height: 17px;
    margin-top: 2px;
}

.fb-os-i-inn img {
    height: 20px;
}

.fb-os-i-inn p {
    margin: 0px;
    width: 195px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.fb-os-i-amt {
    font-family: 'sf-pro-text-bold';
    font-weight: 600;
    font-size: 13px;
    margin-bottom: 0;
}

.fb-os-i-subamt {
    font-family: 'sf-pro-text-medium';
    font-size: 13px;
    margin-bottom: 0;
}

.fb-os-i-cin {
    border-top: 1px solid #e5e5e5;
    padding-top: 15px;
    padding-bottom: 5px;
}

.fb-os-i-cinInn {
    padding: 0px 15px;
}

.fb-os-i-cinIP {
    margin: 0px;
    color: #808190;
    font-size: 14px;
    text-transform: uppercase;
}

.fb-os-i-cinIAdd {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0;
}

.fb-os-i-cinIOut {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 15px;
}

.fb-pno-btns {
    padding: 10px 0px;
    margin-right: 50px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 12px;
    max-width: 750px;
}

.fb-pno-need {
    background-color: white;
    border: 1px solid var(--backgroundColor);
    border-radius: 10px;
    padding: 10px 0px;
    font-weight: 600;
    outline: none;
    font-size: 15px;
    width: 44%;
}

.fb-pno-btn {
    background-color: var(--backgroundColor);
    border: 1px solid transparent;
    border-radius: 10px;
    padding: 10px 30px;
    font-weight: 600;
    outline: none;
    font-size: 15px;
    width: auto;
    color: var(--pColor);
}

.checkboxVegDiv {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    top: 0;
    position: sticky;
    z-index: 9;
    padding: 2px 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

input.fnBSearch {
    width: 100%;
    margin: 7px 0px;
    padding: 12px 10px;
    outline: none;
    font-size: 15px;
    font-weight: 500;
    border: 1px solid #D6D6D6;
    border-radius: 6px;
    caret-color: gray;
    /* background-color: #F9F9F9; */
    border-radius: 30px;
    padding-left: 45px;

}

.fnbItem_outer {
    background-color: #fff;
    margin-bottom: 0px;
}

.emptyCartdiv {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.cart-itemwrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 80vh;
    overflow-x: auto;
    min-height: 80vh;
    padding-bottom: 110px;
}

.cart-itemwrapperdesk {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: auto;
    height: calc(100vh - 195px);
    /* padding-bottom: 180px; */
}

.fnBSearchIcon {
    pointer-events: none;
    position: absolute;
    left: 15px;
}

.buyItem-list.paymentpage {
    padding: 0 10px;
}

.buyItem-list.paymentpage .co-addon-inndiv span {
    font-size: 12px;
}

.buyItem-list.paymentpage .co-itemNameP {
    font-size: 13px;
}

.micIcon {
    position: absolute;
    right: 15px;
    cursor: pointer;
}

.fnBSearchDiv {
    position: relative;
    width: calc(100% - 25px);
    display: flex;
    align-items: center;
}

.fnBSearch::placeholder {
    color: #808190;
}

.fnbSearchback {
    /* transform: rotate(270deg); */
    width: 25px;
}

.fnbSearchbackdesk {
    width: 40px;
    margin-right: 10px;
    cursor: pointer;
}

.no-found-fnb {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60 vh;
}

.no-found-fnbP {
    font-size: 17px;
    text-align: center;
    color: #000;
    margin: 50px 20px;
    font-weight: 500;
}

.showhori-pair {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    /* margin: 5px 10px; */
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    background-color: #f8f6f6;
    padding: 5px 10px;
    overflow: -moz-scrollbars-none;
    -webkit-overflow-scrolling: touch;
}

.showhori-pair::-webkit-scrollbar {
    display: none;
}

.fnb-pwt-ptag {
    color: #808190;
    margin: 0px;
    background-color: #f8f6f6;
    padding: 0px 12px;
    font-size: 13px;
    letter-spacing: 1px;
    padding-top: 5px;
}

.fnbsta-iname {
    width: 100%;
    text-wrap: wrap;
    margin: 0px;
    padding-right: 10px;
    text-transform: capitalize;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    max-width: 58vw;
}

.fb-os-i-innstatus svg {
    margin-top: 2px;
}

.ordpckup {
    font-size: 15px;
    margin-top: 10px;
}

.fnbsta-addonp {
    line-height: 15px;
    margin: 0px;
    /* display: flex; */
    width: 100%;
    flex-direction: row;
}

.os-statusAdd {
    position: absolute;
    right: 1px;
    font-family: var(--fontFamilyRegular);
}

.fnbsta-addon {
    font-size: 12px;
    display: block;
}

.fnbm-neif {
    margin: 30px 10px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
}

.fnbCart-div {
    background-color: #F9F9F9;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 40%;
    display: flex;
    margin: 5px 2.5%;
    padding: 10px 2%;
    border-radius: 10px;
}

.fnbCart-div.activeItems {
    background-color: var(--bgLight);
}

.fnbCart-divInn {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    align-items: center;
    cursor: pointer;
}

.allergensP {
    color: #7A7A7A;
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 2px;
    text-transform: capitalize;
}

.fnb-cartnid {
    font-family: var(--fontFamilyBold);
    margin: 0px;
    font-size: 15px;
    /* font-weight: 500; */
}

.fltIcon {
    position: absolute;
}

.qtydsk {
    text-decoration: underline;
}

.fnbCart-div>.fnb-cartnid {
    font-weight: 600;
}

.fnb-am-iiDiv {
    width: 50%;
    text-align: center;
    border: 1px solid #DDDDDD;
    border-radius: 10px;
    padding: 10px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}


.abtdesk {
    width: calc(50% - 40px);
    flex-shrink: 0;
}

.cmbprice {
    padding-left: 20px;
    padding-top: 5px;
    display: block;
}



.os-itemprice {
    font-family: var(--fontFamilyRegular);
    font-weight: 400;
    font-size: 14px;
}

.fnb-am-iimag {
    height: auto;
    width: 100%;
    /* max-width: 200px; */
    max-height: 250px;
    object-fit: contain;
    /* margin: 25px 0px; */
}

.fnb-m-cart {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    /* box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px; */
}

.fnb-m-cartdesk {
    width: 375px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.fnb-m-cartInn {
    width: 88%;
    padding-bottom: 20px;
    color: black;
    max-height: 60vh;
    overflow: auto;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    margin-bottom: 110px;
    overflow: -moz-scrollbars-none;
    -webkit-overflow-scrolling: touch;
}

.fnb-m-cartInn::-webkit-scrollbar {
    display: none;
}

.fnb-m-head {
    font-size: 20px;
    font-weight: 600;
    width: 90%;
    margin-bottom: 15px;
}

.fnb-m-cartCard {
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    column-gap: 15px;
    border-bottom: 1px solid #EDE8E9;
    padding-bottom: 10px;
    margin-top: 10px;
    width: 92%;
}

.fnb-c-btnP {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    margin-top: 5px;
}

.fnb-c-btnP p {
    font-family: var(--fontFamilyRegular);
    margin-bottom: 0;
    font-weight: 400;
    font-size: 15px;
}

.co-addon-inndiv {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    text-transform: capitalize;
}

.fnb-c-itemname {
    font-weight: 600;
    font-size: 14px;
    margin: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    /* width: 47vw; */
    display: -webkit-box;
    max-width: 100%;
    /* height: 37px; */
    line-height: 17px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-weight: 600;
    text-overflow: ellipsis;
    text-transform: capitalize;
}

.fnb-c-itemnamedesk {
    font-weight: 600;
    font-size: 15px;
    margin: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 250px;
    display: -webkit-box;
    max-width: 100%;
    /* height: 37px; */
    font-size: 15px;
    line-height: 17px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-weight: 600;
    text-overflow: ellipsis;
    text-transform: capitalize;
}

.fnb-c-type {
    margin: 5px 0px;
    font-size: 14px;
}

.fnb-m-cartCardAbo {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.fnb-m-cartiimg {
    position: relative;
    border: 1px solid #EBEBEB;
    border-radius: 6px;
    align-self: flex-start;
}


.cartitemimg {
    width: 80px;
    height: 80px;
    object-fit: contain;
}

.fnbcart-hori-main {
    background-color: #F9F9F9;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-bottom: 110px;
    border-top: 1px solid #EDE8E9;
    border-bottom: 1px solid #EDE8E9;

}

.fnb-m-addon-item {
    font-size: 12px;
    margin: 0px;
    line-height: 13px;
    font-style: italic;
}

.fnb-m-addon-span {
    font-weight: 600;
}

.fnbcart-hori-inn {
    width: 88%;
    padding: 5px 0px;
    display: flex;
    flex-direction: row;
    column-gap: 25px;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: -moz-scrollbars-none;
    -webkit-overflow-scrolling: touch;
}

.fnbcart-hori-inn::-webkit-scrollbar {
    display: none;
}

.fnb-m-Horihead {
    width: 88%;
    margin: 0px;
    margin-top: 15px;
    font-size: 18px;
    font-weight: 600;
}


.cartPriceDesk {
    width: 100%;
}

.cartPriceDesk {
    width: 100%;
    position: sticky;
    bottom: 0;
    padding: 15px 20px;
    border-top: 1px solid #D6D6D6;
    background-color: white;
}

.cartPriceDeskInr {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 15px;
    background-color: #fff;
}

.cartPriceDesk p {
    color: #000;
    margin-bottom: 0;
    font-weight: 600;
}

@media only screen and (max-width: 1024px) {
    .fb-o-qr-p {
        /* text-align: center; */
        font-size: 17px;
    }

    .fltIcon {
        margin: 15px;
    }

    .co-itemNameP {
        /* width: 58vw; */
        font-size: 13px;
    }

    .m-cus-head {
        padding: 15px 4%;
    }

    .qtydsk {
        text-decoration: none;
    }

    .m-offer-hdiv {
        border-bottom: 1px solid #EDE8E9;
        padding: 0 0 10px 0;
    }

    .fnbItem_outer {
        margin-bottom: 110px;
    }

    .m-offer-dec-div {
        height: 45vh;
    }

    .pyo-maindiv {
        margin: 0;
        width: 100%;
    }

    .mncategoriesmob {
        position: sticky;
        top: 55px;
        z-index: 9;
    }

    .m-itemName {
        margin-left: 0px;
    }

    .fb-os-inn {
        margin-bottom: 59px;
    }

    .m-cus-inn {
        width: 90%;
    }

    .fb-os-main {
        background-color: var(--orderStatusBG);
    }

    .fb-os-seat {
        padding: 0% 4%;
    }


    .m-extraH {
        color: #000;
    }

    .m-extraI {
        margin: 0px auto 30px;
        width: 100%;
    }

    .abtdesk {
        width: 100%;
    }

    .abtmodal {
        padding-bottom: 20px;
    }

    .fb-op-cardInn {
        height: 145px;
        gap: 20px;
    }

    .cartPriceDesk {
        position: fixed;
        padding: 10px 15px;
    }

    .cartPriceDeskInr {
        padding-bottom: 10px;
    }

    .fnb-am-iiDiv {
        border: none;
        border-radius: 10px;
        padding: 20px;
    }

    .fb-o-imgdiv {
        width: 100%;
    }

    .fnbCart-div {
        width: 95%;
    }

    .pyo-maindiv {
        position: fixed;
        margin-bottom: 0;
    }

    .outer-fbOption {
        margin: 0px 5%;
    }

    .fnbdeskdiv-inn {
        border: 0;
    }

    .co-outer-maindiv {
        background-color: #f8f6f6;
    }

    .containerfnb {
        border: 0;
        box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
        background-color: #fff;
    }

    .co-outer-maindiv {
        padding-bottom: 65px;
        min-height: 90vh;
    }

    .fnb-am-iiDiv {
        width: 100%;
    }

    .m-c-final {
        position: sticky;
        z-index: 99;
        background: #fff;
    }

    .dts-inn-div {
        flex-direction: column;
        gap: 0;
    }

    .fb-added-btn {
        width: 95%;
        float: none;
        margin: 0;
    }


    .fnb-am-iimag {
        border: 1px solid #EDE8E9;
        border-radius: 10px;
    }

    .fb-pno-btns {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0px;
        bottom: -5px;
        background-color: var(--orderStatusBG);
        padding: 10px 0px;
        width: 100%;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 12px;
        max-width: 840px;
    }

    .fb-pno-btn {
        width: 90%;
    }

    .modal-content {
        border-radius: var(--bs-modal-border-radius) var(--bs-modal-border-radius) 0 0;
        overflow: hidden;
    }
}

@media only screen and (max-width: 359px) and (min-width: 300px) {
    .m-itemName {
        margin-left: 0;
    }

    .Reco-item-vImg {
        height: 70px;
        width: 70px;
    }

    .fb-v {

        font-size: 14px;

    }

    .fb-filter {
        column-gap: 6px;
        margin: 5px 0px;
        padding: 7px 10px;
    }

    .co-addon-div {
        font-size: 10px;
        width: 100%;
    }

    .fb-os-pickup {
        font-size: 11px;
    }

    .fb-os-i-amt {
        font-size: 15px;
    }

    /* .os-itemprice {
        font-size: 14px;
        line-height: 16px;
        font-family: 'sf-pro-text-medium';
    } */

    .fnbsta-iname {
        font-size: 13px;
        line-height: 16px;
    }

    .fb-os-pickup span {
        font-size: 12px;
    }

    .fnb-c-itemname {
        font-size: 14px;
        /* height: 37px; */
        font-size: 14px;
        line-height: 18px;

    }

    .m-fb-desc {

        max-width: 170px;

    }

    .co-pricep {
        font-size: 13px;
        line-height: 18px;
        width: 65px;
    }


    .fb-itemName {

        font-size: 14px;
        margin-top: 5px;
        line-height: 17px;
    }

    .fnb-disoff {
        font-size: 14px;
    }

    .fnb-disId {
        font-size: 14px;
    }

    .fnb-dis-div {
        min-width: 250px;
        width: 250px;
    }

    /* .Reco-item-viewImg {
        height: 65px;
        width: 65px;
    } */

    .Reco-itemPrice {
        font-size: 13px;
    }

    .Reco-card-inn {
        width: 250px;
        padding: 5px 5px;
    }

    .off-vaild {
        font-size: 11px;
        line-height: 15px;
    }

    .fboffer-more {
        margin-top: 2px;
    }

    .o-applyBtn {
        font-size: 12px;
        padding: 4px 9px;
    }


    .o-off {
        font-size: 12px;
    }

    .fb-a-aLink {
        font-size: 15px;
    }

    .Reco-itemVorN {
        top: 5px;
        left: 5px;
        height: 17px;
        max-height: 17px;
        max-width: 17px;
    }

    /* .fnb-itemNon {
        top: 14.5px;
        left: 14.5px;
        height: 13px;
        max-height: 13px;
        max-width: 13px;
    } */

    .Reco-itemNon {
        top: 6.5px;
        left: 6.5px;
        height: 13px;
        max-height: 13px;
        max-width: 13px;
    }

    .fnb-m-addon-item {
        font-size: 11px;
    }
}

@media only screen and (max-width: 350px) and (min-width: 300px) {
    .co-ra-ptag {
        font-size: 14px;
    }

    .fnbsta-addon {
        font-size: 11px;
    }

    .step-label {
        font-size: 14px;
    }

    .Fnbstep-icon {
        margin-right: 10px;
    }

    .co-ra-div img {
        width: 60px;
    }

    .fb-pyo {
        font-size: 14px;
    }

    .fb-os-audi {
        font-size: 14px;
    }
}