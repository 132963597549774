@font-face {
    font-family: "sf-pro-text-regular";
    src: url("../../../src/assets/fonts/sf-pro-text/sf-pro-text-regular.ttf");
    font-weight: 500;
}

@font-face {
    font-family: "sf-pro-text-medium";
    src: url("../../../src/assets/fonts/sf-pro-text/sf-pro-text-medium.ttf");
    font-weight: 600;
}

@font-face {
    font-family: "sf-pro-text-bold";
    src: url("../../../src/assets/fonts/sf-pro-text/sf-pro-text-bold.ttf");
    font-weight: 600;
}

/* pvrTheme */
.pvrTheme {
    --backgroundColor: #FFCB05;
    --backgroundModalColor: #f6da68;
    --bgGradient: linear-gradient(334deg, #E76F00 00%, #FFEB34 100%);
    --pColor: #000;
    --bgLight: #FFFAE6;
    --modalBgColor: #FFE194;
    --modalColor: #EF761A;
    --borderColor: #FFFDF5;
    --fontWeight: 700;
    --fontFamilyRegular: "sf-pro-text-regular";
    --fontFamilyMedium: "sf-pro-text-medium";
    --fontFamilyBold: "sf-pro-text-bold";
}

/* inoxTheme */
.inoxTheme {
    --backgroundColor: #234B9E;
    --backgroundModalColor: #cedeff;
    --bgGradient: linear-gradient(334deg, #234B9E 00%, #D9ECDE 100%);
    --pColor: #fff;
    --bgLight: #EDF3FF;
    --modalBgColor: #234B9E;
    --modalColor: #FFF;
    --borderColor: #F5F9FF;
    --fontWeight: 600;
    --fontFamilyRegular: "sf-pro-text-regular";
    --fontFamilyMedium: "sf-pro-text-medium";
    --fontFamilyBold: "sf-pro-text-bold";
}

/*----------------------------------------------------------
 Modal Popup 
 ----------------------------------------------------------*/
.slideTopModal.modal.show .modal-dialog {
    margin: 0px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
}

.slideTopModal.modal-content {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

/* Keyframes for sliding up from the bottom */
@keyframes slideInUp {
    from {
        transform: translateY(100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Keyframes for sliding out to the bottom */
@keyframes slideOutDown {
    from {
        transform: translateY(0);
        opacity: 1;
    }

    to {
        transform: translateY(100%);
        opacity: 0;
    }
}

.slideTopModal .modal-dialog {
    animation: slideInUp 0.3s forwards;
    /* Adjust timing as needed */
}

.slideTopModal .modal.fade .modal-dialog {
    animation: slideInUp 0.3s forwards;
}

.slideTopModal .modal.fade .modal-dialog.modal-hide {
    animation: slideOutDown 0.3s forwards;
}

.slideTopModal .modal-content,
.centerModal .modal-content {
    padding: 25px;
    border-radius: 25px 25px 0 0;
    text-align: center;
}

.slideTopModal .modal-content {
    max-height: 95vh;
    overflow: auto;
    width: 100vw;
}

.slideTopModal .modal-content h3 {
    color: #000;
    margin: 15px 0;
}

.slideTopModal .modal-content>p {
    font-size: 15px;
    color: #000;
    margin-bottom: 25px;
}

.centerModal.p0 .modal-content,
.slideTopModal.p0 .modal-content {
    padding: 0;
}


.fixbtmbtn {
    position: sticky;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px;
    background-color: #fff;
}

.bkngbtn {
    margin: 0 20px;
}

.w100 {
    width: 100%;
}

.modalbtnCon {
    border-top: 1px solid #EDE8E9;
    padding: 10px 20px;
}

.fdrdmbtn {
    margin-top: 30px;
}

.modalbtn {
    display: inline-block;
    background-color: var(--backgroundColor);
    color: var(--pColor);
    border: 0;
    padding: 13px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 10px;
    /* width: calc(50% - 4px); */
    text-align: center;
    cursor: pointer;
}

.confirmbtn {
    background-color: #1A8C2D;
    color: #FFF;
    margin-left: 8px;
    width: 35%;
}

.removebtn {
    background: transparent;
    border: 0;
    text-decoration: underline;
    color: #DD0000;
    font-size: 12px;
    text-transform: capitalize;
    cursor: pointer;
}

.cancelbtn {
    background-color: var(--backgroundColor);
    color: var(--pColor);
    width: calc(65% - 8px);
}

.outlineBlack {
    background-color: transparent;
    border: 1px solid #000;
    color: #000;
}

.centerModal.modal.show .modal-dialog {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Center Modal  */
.centerModal .modal-content {
    position: relative;
    max-width: 95%;
    border-radius: 25px;
}

.modaltimerIcon {
    width: 60px;
    height: 70px;
    fill: #BF0000;
}

.modaltimerIcon path {
    fill: #BF0000;
}

.centerModal h3 {
    font-size: 16px;
    font-weight: 500;
    margin: 10px 0;
}

.centerModal p {
    font-size: 13px;
    color: #7A7A7A;
    /* margin-bottom: 25px; */
}

.centerModal .dismissbtn {
    background: transparent;
    border: 1px solid #000000;
    border-radius: 25px;
    padding: 10px;
    line-height: 16px;
}

.clsbtn {
    width: 40px;
    height: 40px;
    background-color: var(--modalBgColor);
    padding: 12px;
    border-radius: 50%;
    position: absolute;
    top: -20px;
    right: 0;
}

.clsbtn>path {
    stroke: var(--modalColor);
}

.ofrmdl .modal-content {
    background: var(--bgGradient);
    border: 0;
}

/* Food Offer Popup */
.mdlhdr {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}

.mdlhdr h3 {
    font-size: 36px;
    color: #FFF;
    margin: 40px 0;
}

p.mdcpncode {
    color: #FFF;
    font-size: 30px;
    font-weight: 500;
}

p.mddisc {
    font-size: 16px;
    line-height: 16px;
    color: #fff;
    font-weight: 500;
}

.mdbtn {
    background: transparent;
    border: 2px solid #fff;
    border-radius: 10px;
    padding: 15px;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
    margin-top: 15px;
    box-shadow: inset 0 0 0 0 rgb(255 255 255 / 30%);
    -webkit-transition: ease-out 0.4s;
    -moz-transition: ease-out 0.4s;
    transition: ease-out 0.4s;
}

.mdbtn:hover {
    box-shadow: inset 423px 0 0 0 rgb(255 255 255 / 30%);
}

.offer-cardOuter .p-tabview .p-tabview-nav {
    justify-content: space-around;
}


.offer-cardOuter .p-tabview .p-tabview-panels {
    width: 70%;
    margin: auto;
}

/* Value Voucher Popup */
.slideTopModal.vchrModal .modal-content {
    text-align: left;
    border: 0;
}

.slideTopModal .modal-content .vchTitle h3 {
    font-size: 17px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 8px;
}

.slideTopModal .modal-content .vchTitle p {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 20px;
    text-align: center;
}

.slideTopModal .modal-content .vchcpn h3 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
}

.slideTopModal .modal-content .vchcpn p {
    font-size: 12px;
    color: #7A7A7A;
    margin-bottom: 20px;
}

.vchcpn {
    border-bottom: 1px solid #EDE8E9;
}

.slideTopModal .modal-content .vchDetails h3 {
    font-size: 15px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 10px;
}

.vchdot {
    padding-left: 20px;
    list-style: none;
}

.vchdot li {
    position: relative;
    font-size: 14px;
    padding-bottom: 10px;
    color: #000;
}

.vchdot li:before {
    content: "";
    width: 3px;
    height: 3px;
    background: black;
    display: block;
    position: absolute;
    left: -14px;
    top: 8px;
    border-radius: 50%;
}

.vchwork {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    border-top: 1px solid #EDE8E9;
    padding: 15px 0;
}

.vchwrkdisc {
    width: 100%;
}

.vchwork svg {
    flex-shrink: 0;
}

.vchrModal .modal-content {
    padding: 25px 25px 100px;
}


.slideTopModal .modal-content .vchwrkdisc h3 {
    font-size: 15px;
    line-height: 15px;
    font-weight: 600;
    margin: 0 0 10px;
}

.slideTopModal .modal-content .vchwrkdisc p {
    font-size: 12px;
    line-height: 12px;
    color: #808190;
    margin-bottom: 0;
}

.vchprcmn {
    display: flex;
    align-items: center;
    justify-content: center;
}


.vchvalueinr {
    display: flex;
    align-items: center;
    gap: 10px;
    border-top: 1px solid #EDE8E9;
    padding: 15px 0;
}

.vchpric {
    position: absolute;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
}

.vcprcdis {
    width: 100%;
}

.prcmn {
    display: flex;
    align-items: center;
    gap: 10px;
}

.prcmn p,
.prcdiscount {
    margin-bottom: 0;
    color: #808190;
    font-size: 14px;
}

.prcmrp {
    text-decoration: line-through;
    text-decoration-color: #C33737;
}

.prcdiscount {
    background-color: #1A8C2D;
    font-size: 11px;
    line-height: 11px;
    color: #FFF;
    display: inline-block;
    padding: 5px 10px;
    border-radius: 25px;
}

/* .custom-radio {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
} */

.custom-radio input[type="radio"] {
    display: none;
}

.custom-radio label {
    display: flex;
    /* align-items: center;
    cursor: pointer; */
}

.custom-radio .icon {
    width: 20px;
    height: 20px;
    border: 2px solid #908E8E;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    overflow: hidden;
}

.custom-radio .icon img {
    display: none;
    width: 100%;
    height: 100%;
}

.custom-radio input[type="radio"]:checked+label .icon {
    border-color: #000;
    background-color: var(--backgroundColor);
}

.custom-radio input[type="radio"]:checked+label .icon img {
    display: block;
}

.vchfixbtn {
    padding: 0;
}

.vchrbtn {
    width: 100%;
}

.vchflxbtn {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 20px;
}

.vchflxbtn>* {
    width: 100%;
}

.tncmn {
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #F8F8F8;
    padding: 10px 20px;
    margin-bottom: 0;
}

.tncmn #tnc {
    accent-color: #000;
}

.tnctxt {
    text-decoration: underline;
}

.tncmn label {
    display: inline-block;
    font-size: 14px;
}

.grndttl {
    font-size: 13px;
    /* line-height: 13px; */
    color: #000;
}

.grndTotal {
    font-size: 18px;
    line-height: 18px;
    font-weight: 600;
}

/* --------------------------------------------------------------
Form Css
-------------------------------------------------------------- */


.errmsg {
    color: #ff3333;
    font-size: 11px;
    margin-bottom: 0;
}

/* -------------------------------------------------------------- */
.offerbanner {
    background: #000000;
    padding: 15px 0;
}

.foodSliderli {
    padding: 0 5px;
}

.offerbanner h3 {
    font-size: 56px;
    line-height: 56px;
    background: -webkit-linear-gradient(#FFF, #4F4F4F);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
    text-align: center;
    padding: 0 10px;
}

.offerbanner p {
    color: #fff;
    text-align: center;
    padding: 0 20px;
    font-size: 14px;
    margin-bottom: 10px;
}

.FoodCartPage .foodCard {
    margin-left: -7px;
    margin-right: -7px;
}

.foodCard {
    padding: 20px 15px;
    display: flex;
    flex-wrap: wrap;
}

.foodCardinr {
    flex-shrink: 0;
    padding: 7px;
    width: 50%;
}

.foodCardli {
    background: #FFF;
    border-radius: 10px;
    border: 1px solid #E8E8E8;
    border-radius: 10px;
    overflow: hidden;
}

.foodDisc {
    padding: 10px;
    /* border: 1px solid #E8E8E8; */
    box-shadow: 0 1px 0 #E8E8E8;
    /* border-radius: 0 0 10px 10px; */
}

.foodDisc h4 {
    font-size: 13px;
    font-weight: 600;
    text-transform: capitalize;
    color: #000;
    margin-bottom: 5px;
}

.foodDisc p {
    font-size: 11px;
    color: #7A7A7A;
}

/* .expiredvcher img {
    filter: grayscale(1);
}

.foodDisc p.expiredTag {
    color: red;
} */

.cartIcon {
    position: relative;
}

.cartCount {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: #FF7622;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 14px;
    color: #fff;
    font-weight: 600;
}

.vouTabs.p-tabview .p-tabview-nav li .p-tabview-nav-link {
    padding: 0 15px 15px;
    /* justify-content: center; */
}

.vouTabs .payment-feildp {
    font-size: 12px;
    margin-bottom: 10px;
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar {
    background-color: var(--backgroundColor);
}

.p-tabview-title {
    font-size: 16px;
    color: #000;
    font-weight: 500;
}

/*-----------------------------------------------------
    Redeem Offer Page
-----------------------------------------------------*/
.contentPage {
    width: 70%;
    padding: 20px;
    margin: auto;
}

.pb100 {
    padding-bottom: 100px;
}

hr.hrline {
    margin: 25px -25px 0;
}

.inrHdrPage {
    display: flex;
    align-items: center;
    gap: 10px;
    /* margin-bottom: 25px; */
    padding: 10px 20px;
    box-shadow: none;
}

.inrHdrPage .fnb-os-p {
    margin: 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
}

.inrHdrPage .hdrsearch {
    display: none;
}

.rdmDisc {
    border-bottom: 1px solid #EDE8E9;
    padding: 20px 0;
}

.OfferActivatedAll {
    padding-top: 20px;
}

.offerRedeemPage img {
    border-radius: 6px;
}

.offerRedeemPage h4 {
    font-weight: 600;
    font-size: 18px;
    /* margin: 5px; */
    color: #000;
    text-transform: capitalize;
}

.offerRedeemPage p {
    font-size: 14px;
    color: #7A7A7A;
    margin-bottom: 0;
}

.terms {
    padding-top: 20px;
}


.terms>h4 {
    font-size: 16px;
    margin-bottom: 10px;
}

.termsul {
    padding-left: 15px;
}

.termsul li {
    color: rgb(0 0 0 / 70%);
    font-size: 12px;
    line-height: 18px;
    padding-bottom: 10px;
    list-style: decimal;
}

.getbtmbtn {
    display: flex;
    justify-content: end;
    margin: 20px;
}

.cmnbtn {
    background-color: var(--backgroundColor);
    font-size: 16px;
    color: var(--pColor);
    font-weight: 600;
    display: block;
    padding: 10px 30px;
    text-align: center;
    border: 0;
    border-radius: 10px;
    width: auto;
}



.cmnbtn.upgradebtnNo {
    background-color: #EDE8E9;
    padding: 13px 15px;
    width: 35%;
}

.cmnbtn.upgradebtnYes {
    padding: 13px 15px;
    width: 65%;
}

/*-----------------------------------------------------
    Activated Voucher
-----------------------------------------------------*/
.qrcodesvg {
    padding: 10px;
    border: 1px solid #000;
    background-color: #fff;
}

.qrcodevhr {
    text-align: center;
    padding: 15px;
    background: #F8F6F6;
    border-bottom: 1px solid #E8E8E8;
}

/*-----------------------------------------------------
    Offer Activated
-----------------------------------------------------*/
.qrcodeDetails {
    background-color: #F8F6F6;
    border: 1px solid #CCCCCC;
    text-align: center;
    border-radius: 15px;
    box-shadow: 0 2px 0 #CCCCCC;
}

.qrcodeDetails>* {
    padding: 20px;
}

.qrcode {
    background-color: #fff;
    border: 1px solid #000;
    max-width: 264px;
    height: 264px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cpncode {
    font-size: 18px;
    line-height: 18px;
    color: #000;
    margin: 20px 0 0;
    font-weight: 600;
    text-transform: uppercase;
}

.qrTitle {
    padding: 15px 20px;
    text-align: left;
    border-top: 1px solid #CCCCCC;
}

.qrTitle h4 {
    font-size: 16px;
    color: #000;
    font-weight: 600;
}

.qrTitle p {
    font-size: 14px;
    margin-bottom: 0;
}

.offerActivated .terms>h4 {
    font-weight: 600;
}

.countTimer {
    display: block;
    width: 100%;
    background-color: #E2F4E5;
    padding: 5px 10px;
    color: #000;
}

.timerbar {
    height: 4px;
    background-color: #FFF;
    border-radius: 3px;
}

.timerinrbar {
    height: 100%;
    background-color: #1A8C2D;
    border-radius: 3px;
}

.countTimerinr {
    display: flex;
    justify-content: space-between;
    gap: 15px;
}

.endtimeCount {
    background: var(--backgroundColor);
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
    color: var(--pColor);
    border-radius: 50%;
    margin-bottom: 0;
}

.timerTile {
    display: flex;
    gap: 5px;
    align-items: center;
    margin-bottom: 10px;
}

.timerTile span,
.countDown {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
    flex-shrink: 0;
}

.offerTimer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #FFF;
    padding: 8px 5px;
    box-shadow: 0 -4px 4px rgb(0 0 0 / 25%);
}

.ofractv {
    left: unset;
    right: unset;
}

.p0 {
    padding: 0;
}

.offeractall .terms {
    padding: 20px 20px 0;
}

.offeractall hr.hrline {
    margin: 25px 0 0;
}

.offeractall .qrcodeDetails {
    margin: 0 7px;
}

/*-----------------------------------------------------
    Your Cart
-----------------------------------------------------*/
.cartdv {
    text-align: center;
    top: 30%;
    position: absolute;
    left: 20px;
    right: 20px;
}

.cartdv h3 {
    font-size: 18px;
    font-weight: 600;
    margin: 15px 0;
}

.cartdv p {
    padding: 0 15px;
    color: #000;
}

.btnround {
    background-color: var(--backgroundColor);
    width: 50%;
    display: inline-block;
    padding: 10px;
    border-radius: 25px;
    color: var(--pColor);
    font-weight: 600;
    font-size: 16px;
    margin-top: 10px;
    cursor: pointer;
}

/*-----------------------------------------------------
    Food & Beverages | My Account
-----------------------------------------------------*/
.pyo-maindiv .swiper-button-prev:after,
.pyo-maindiv .swiper-button-next:after {
    font-size: 12px;
    color: #000;
    background-color: #fff;
    border-radius: 50%;
    padding: 10px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #e0e0e0;
}

.pyo-maindiv .swiper {
    position: static;
    width: 100%;
}

.pyo-maindiv .swiper-slide {
    margin-bottom: 0 !important;
}

.pyo-maindiv .swiper-button-next,
.pyo-maindiv .swiper-rtl .swiper-button-prev {
    right: -30px;
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
    left: -30px;
}

/* .pckupstatus {
    font-size: 14px;
    line-height: 14px;
    background-color: var(--backgroundColor);
    padding: 10px;
    color: var(--pColor);
    font-weight: 600;
    text-align: center;
} */

.pckupstatus {
    font-size: 13px;
    line-height: 13px;
    background-color: #E9FFED;
    padding: 5px 10px;
    color: #000;
    font-weight: 400;
    text-align: left;
}

.moviedetails {
    display: flex;
    background-color: #F9F9F9;
    padding: 15px;
    gap: 15px;
    border: 1px solid #EDE8E9;
    border-bottom: 0;
    cursor: pointer;
}

.movdets {
    width: 100%;
    position: relative;
}

.movTitle {
    font-size: 15px;
    line-height: 15px;
    font-weight: 600;
    color: #000;
    margin-bottom: 5px;
    text-transform: capitalize;
}

.movTags {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 0 20px;
}

.movTags li,
.cmnfnt {
    position: relative;
    font-size: 13px;
    line-height: 16px;
    color: #000;
    margin-bottom: 5px;
}

.movTags li:before {
    content: "";
    width: 5px;
    height: 5px;
    position: absolute;
    top: 4px;
    left: -12px;
    background-color: #7A7A7A;
    border-radius: 50%;
}

.movTags li:first-child:before {
    display: none;
}

.disabled {
    pointer-events: none;
    opacity: .5;
}

.movieImg {
    flex-shrink: 0;
}

.foodIcon img {
    background: var(--bgLight);
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #EDE8E9;
}

.FoodItems .movTitle {
    font-weight: 500;
}

.movDirection {
    border: 0;
}

/* .movDirection svg {
    position: absolute;
    right: 0;
    bottom: 0;
} */

.fbhist {
    display: block;
    margin: 15px 0;
    border-radius: 8px;
    overflow: hidden;
}

.prdtitle {
    color: #000;
    padding-right: 5px;
    text-transform: capitalize;
}

.fdTitle {
    display: flex;
    align-items: start;
    gap: 10px;
}

.addflx {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.movAdds {
    line-height: 18px;
    margin-bottom: 0;
}

.fditems {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 15px 10px;
    border: 1px solid #EDE8E9;
    border-bottom: 0;
    gap: 10px;
}

.fditems:last-child {
    border: 1px solid #EDE8E9;
}

.fdName,
.itmcount {
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    margin-bottom: 0;
    color: #000;
    text-transform: capitalize;
}

.titleHist>p {
    font-size: 12px;
    line-height: 12px;
    margin: 10px 0 0;
    font-weight: 400;
}

.itmcount {
    font-weight: 400;
}

.orderdetails {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #F9F9F9;
    border: 1px solid #EDE8E9;
    /* padding: 10px; */
}

.ordstatus {
    font-family: 'Poppins';
    background-color: #F9F9F9;
    color: #000;
    font-size: 12px;
    width: 100%;
    padding: 8px 12px;
    border-radius: 5px;
    margin-right: 10px;
    font-weight: 600;
    border: 1px solid #EDE8E9;
    text-wrap: nowrap;
    cursor: pointer;
}

/* .ordstatus {
    font-family: 'sf-pro-text-medium';
    background-color: #1A8C2D;
    color: #fff;
    font-size: 14px;
    text-decoration: underline;
    width: 100%;
    padding: 10px;
    border: 0;
} */

.fixbtmbtn .ordstatus {
    font-family: 'sf-pro-text-medium';
    color: #fff;
    width: 100%;
    margin-right: 10px;
    border: 0;
    cursor: pointer;
    background-color: #149752;
    text-decoration: none;
    border-radius: 10px;
    padding: 15px;
    font-size: 14px;
}

.ticket-details {
    min-height: 90vh;
}

.ordNo {
    font-size: 13px;
    line-height: 13px;
    color: #000;
    margin-bottom: 0;
}

.delstatus {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
}

.orderdetails>* {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 10px;
    width: 50%;
}

.fddwninvce {
    /* background-color: #fff;
    border: 1px solid #EDE8E9;
    padding: 8px 12px;
    border-radius: 25px; */
    font-size: 13px;
    line-height: 13px;
    font-weight: 500;
    color: #000;
    text-align: center;
    border-right: 1px solid #EDE8E9;
}


.hsfddwn.fddwninvce {
    background: #F1F1F1;
    padding: 2px 8px;
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
    border-radius: 25px;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
    border-bottom-width: 2px;
}

.bgColor {
    background-color: var(--backgroundColor);
    color: var(--pColor);
    width: 100%;
}

@media (max-width: 575px) {
    .flterinr {
        justify-content: space-between;
    }
}

@media (max-width: 359px) {
    .movTags {
        flex-wrap: wrap;
    }

    .movTags li:last-child:before {
        display: none;
    }
}

.plchlder {
    color: #a0a0a0;
    font-size: 14px;
    font-style: italic;
    opacity: 0.7;
}

.nodatamsg {
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-weight: 600;
    color: #d1d1d1;
    padding: 10px;
    text-align: center;
}

.bkngdlts {
    border: 1px solid #BFC0C7;
    border-radius: 10px;
    overflow: hidden;
    margin: 0 20px;
}

.bdmovdts {
    display: flex;
    background-color: #EDEDED;
    padding: 10px;
    gap: 15px;
    border: 1px solid #EDE8E9;
    border-bottom: 0;
}

.bdmovdets {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.movdtme {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    border-top: 1px solid #BFC0C7;
    /* border-bottom: 1px dashed #BFC0C7; */
}

.mvdt {
    padding-bottom: 15px;
    width: 50%;
}

.mvdt:nth-child(2) {
    /* text-align: right; */
    padding-left: 10px;
}

.totalsmry .prcfont {
    font-family: 'sf-pro-text-medium';
}

.mvdt:last-child {
    width: 100%;
    padding-bottom: 0;
}

.seatul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 6px;
}

.seatul li {
    background-color: #fff0d6;
    display: inline-block;
    border: 1px solid#fff0d6;
    padding: 5px;
    line-height: 12px;
    font-size: 12px;
    border-radius: 5px;
    color: #000;
    font-weight: 600;
    text-transform: uppercase;
}

.inrTitle {
    font-size: 13px;
    line-height: 13px;
    color: #808190;
    margin-bottom: 10px;
    text-transform: uppercase;
}

.mvdt h5 {
    color: #000;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;
}

.fdflx {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.fdflx p {
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
}

.fdofers {
    padding: 20px;
}

.fdcnter {
    color: #000;
    background-color: var(--bgLight);
    padding: 10px;
    text-align: center;
    border-bottom: 1px solid var(--backgroundColor);
    border-top: 1px solid var(--backgroundColor);
}

.fdcnter h3 {
    font-size: 18px;
    font-weight: 600;
}

.fdcnter p {
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    margin: 10px 0;
}

.ordid {
    font-size: 15px;
    line-height: 15px;
}

.tckid {
    font-weight: 500;
    text-transform: uppercase;
}

.smrttl {
    padding: 10px;
}

.smrttl tr {
    position: relative;
}

.smrttl th {
    color: #000;
    padding: 5px 0;
}

.smrttl td {
    font-family: var(--fontFamilyMedium);
    font-size: 14px;
    font-weight: 500;
    padding: 5px 0;
    vertical-align: top;
}

/* .smrttl span {
    padding-left: 10px;
} */

td:last-child,
th:last-child {
    text-align: right;
}

.accview {
    font-size: 15px;
    border-top: 1px solid #BFC0C7;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    color: #7D7D7D;
    font-weight: 500;
    transition: all 0.3s ease;
    cursor: pointer;
}

.accview svg {
    transform: rotate(180deg);
    transition: transform 0.3s ease;
}

.accview.expanded svg {
    transform: rotate(0deg);
}

.totalsmry {
    border: 1px solid #BFC0C7;
    padding: 10px;
    border-radius: 10px;
    margin: 15px 20px;
}

.totalsmry th {
    color: #000;
    padding: 5px 0;
    font-weight: 500;
}

.totalsmry td {
    font-size: 13px;
    color: rgb(0 0 0 / 70%);
    padding: 5px 0;
}

.smrttl td {
    font-size: 13px;
    font-weight: 500;
    padding: 5px 0;
}

td.txtitle {
    text-align: left;
    font-weight: 600;
    color: #000;
    padding-top: 15px;
}

td.tckid {
    color: #000;
}

@media (max-width: 359px) {

    .totalsmry td,
    .smrttl td {
        font-size: 12px;
    }

    .fdcnter h3 {
        font-size: 16px;
    }

    .totalsmry th,
    .smrttl th,
    .mvdt h5,
    .accview,
    .ordid,
    .fdcnter p {
        font-size: 14px;
        line-height: 14px;
    }

    .movTags li,
    .cmnfnt,
    .inrTitle {
        font-size: 12px;
        line-height: 12px;
    }

    .seatul li {
        line-height: 10px;
        font-size: 10px;
    }

    .movAdds {
        line-height: 16px;
    }
}

/* 
.content {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s ease, opacity 0.3s ease;
    opacity: 0;
} */

/* .content.show {
    max-height: 100px;
    opacity: 1;
}

.content.hide {
    max-height: 0;
    opacity: 0;
} */

/*-----------------------------------------------------
    Food Details Css
-----------------------------------------------------*/

.cardTabs .p-tabview-nav-container {
    /* border: 1px solid #EDE8E9;
    border-radius: 25px; */
    overflow: hidden;
}

.cardTabs li {
    width: 100%;
}

.cardTabs.p-tabview .p-tabview-nav li .p-tabview-nav-link {
    font-size: 16px;
    line-height: 16px;
    padding: 10px;
    justify-content: center;
    color: #000;
    font-weight: 500;
    border-radius: 25px;
    margin: 0;
    border: 1px solid transparent;
    z-index: 9;
}

/* .cardTabs.p-tabview .p-tabview-nav {
    border: 0px solid #000000;
} */

.cardTabs.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    border-color: var(--backgroundColor);
    background-color: var(--bgLight);
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    box-shadow: var(--bgLight)
}

.cardTabs.p-tabview .p-tabview-nav .p-tabview-ink-bar {
    display: none;
}

.cardTabs.p-tabview .p-tabview-panels {
    padding-left: 0;
    padding-right: 0;
}

.cardTabs.p-tabview .p-tabview-nav {
    border: 1px solid #EDE8E9;
    border-radius: 25px;
}

.cardTabs.p-tabview .p-tabview-panels {
    padding: 0;
}

.cardTabs .frmgrp {
    border: 1px solid #ededed;
    padding: 20px;
    border-radius: 10px;
    margin: 15px 0 20px;
}

.prmcode.cardTabs .frmgrp {
    margin-top: 0;
}

.cardTabs .frmgrp label {
    font-size: 13px;
    line-height: 13px;
    color: #908E8E;
}

.cardTabs .frmgrp .frmcntrl {
    border: 0px;
    /* padding-top: 5px; */
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
    width: 100%;
}

.flxtd {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
}

.flxtd svg,
.fdTitle svg {
    flex-shrink: 0;
    /* margin-top: 3px; */
}

.cnd {
    font-size: 12px;
    background-color: #F8F8F8;
    border: 1px solid #ECECEC;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 85px;
}

.cnd h3 {
    font-size: 16px;
    font-weight: 600;
}

.tncDisc {
    font-size: 12px;
}

.ttlamt {
    display: flex;
    align-content: center;
    justify-content: space-between;
    background-color: var(--borderColor);
    border: 1px solid #EDE8E9;
    padding: 15px 20px;
}

.ttlamt>p {
    font-family: var(--fontFamilyMedium);
    margin-bottom: 0;
    font-weight: 600;
}

.ttlamt>p:first-child {
    font-weight: 600;
}

/*-----------------------------------------------------
    Cinema In Out Css
-----------------------------------------------------*/
.cinemainout {
    position: relative;
    background: var(--backgroundColor);
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    padding: 0px 35px;
    min-height: 100vh;
    padding-top: 5vh;
}

.cinemainout:after {
    content: "";
    background: var(--backgroundColor);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: -1;
}

.cinmacard {
    background: var(--bgLight);
    width: 100%;
    text-align: center;
    margin-top: 20px;
    border-radius: 10px;
    border: 1px solid #EDE8E9;
    overflow: hidden;
    box-shadow: 0 2px 2px hsl(0deg 0% 0% / 24%);
    cursor: pointer;
}

.pickTheater {
    font-size: 14px;
    line-height: normal;
    font-weight: 600;
    text-align: center;
    padding: 15px 10px;
}

.pvrlogo img {
    margin-bottom: 30px;
}

.icons {
    background-color: var(--bgLight);
    padding: 44px;
}

.cinmacard p {
    font-size: 18px;
    font-weight: 600;
    background: #fff;
    color: #000;
    margin: 0;
    padding: 15px;
    border-top: 1px solid #EDE8E9;
}

.locationModal img {
    width: 80%;
    margin: 15px auto;
}

.locationModal.slideTopModal .modal-content h3 {
    font-size: 16px;
    font-weight: 600;
    padding: 0 15px;
    margin-top: 0;
    margin-bottom: 10px;
}

.locationModal.slideTopModal .modal-content>p {
    font-size: 13px;
    color: #6C6C6C;
    padding: 0 15px;
    margin-bottom: 30px;
}

/*-----------------------------------------------------
    Cinema Listing Css
-----------------------------------------------------*/
.movieupcmngdetails {
    display: flex;
    padding-bottom: 10px;
    gap: 15px;
    border-bottom: 1px solid #E1E1E1;
    cursor: pointer;
    /* border-radius: 6px; */
    margin-bottom: 15px;
    position: relative;
}

.slideTopModal .modal-content h3.upTitle {
    font-size: 16px;
    font-weight: 800;
    margin-top: 0;
    margin-bottom: 5px;
}

.dislc {
    font-size: 14px;
    color: #000;
    margin-bottom: 20px;
}

.centerModal h3.movUpTitle,
.slideTopModal .modal-content h3.movUpTitle {
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 8px;
    text-align: left;
    color: #000;
}



svg.rgtarwup {
    position: absolute;
    right: 10px;
    top: 10px;
}

.centerModal p.cmnUpfnt,
.cmnUpfnt {
    font-size: 12px;
    margin-bottom: 8px;
    line-height: 12px;
    text-align: left;
    color: #808190;
}


.centerModal p.skipUpcmng,
.skipUpcmng {
    font-size: 14px;
    color: #000;
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 0;
}

.modal-backdrop {
    --bs-backdrop-opacity: 0.8;
    backdrop-filter: blur(1px);
}

@media (min-width: 1025px) {
    .centerModal.upcmngModal {
        background: linear-gradient(180deg, var(--backgroundModalColor) 00%, rgba(255, 255, 255, 1) 50%);
    }

    .centerModal.upcmngModal.modal .modal-dialog {
        max-width: 800px;
        align-items: baseline;
    }

    .centerModal.upcmngModal .modal-dialog .modal-content {
        background: transparent;
        border: 0;
        width: 100%;
        max-width: 100%;
    }

    .movieupcmngdetails:last-child {
        margin-bottom: 0;
        padding: 0;
        border: 0;
    }

    .bckarw {
        background: #FFFFFF;
        position: absolute;
        left: 0;
        top: -5px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
    }

    .centerModal.upcmngModal h3.upTitle {
        position: relative;
        font-size: 26px;
        font-weight: bold;
        color: #000;
        margin-bottom: 20px;
    }

    .centerModal.upcmngModal p.dislc {
        font-size: 18px;
        color: #000;
        border-bottom: 2px solid #F1F1F1;
        padding-bottom: 20px;
        margin-bottom: 20px;
    }

    .upcmnglist {
        background: #fff;
        padding: 20px;
        padding-right: 0;
        border-radius: 10px;
        max-width: 500px;
        margin: 30px auto;
        box-shadow: 0 4px 20px hsl(0deg 0% 0% / 20%);
    }

    .upcmnglistinr {
        max-height: 65vh;
        overflow: auto;
        padding-right: 20px;
    }

    .upcmnbtn {
        background: #FFCB05;
        border: 0;
        font-size: 14px;
        font-weight: 500;
        padding: 6px 24px;
        border-radius: 5px;
        position: absolute;
        right: 0;
        bottom: 10px;
    }

    .movieupcmngdetails:last-child .upcmnbtn {
        bottom: 0;
    }

    .flxbtn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 500px;
        margin: auto;
    }
}

/*-----------------------------------------------------
    Footer menu Css
-----------------------------------------------------*/
.fndFooter {
    padding-bottom: 58px;
}

.fndFooterinr {
    display: flex;
    justify-content: space-between;
    list-style: none;
    box-shadow: 0 -4px 4px hsl(0deg 0% 0% / 25%);
    position: fixed;
    width: 100%;
    bottom: 0;
    padding: 10px;
    background-color: #FFF;
}

.fndFooterinr .fndFooterli {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    line-height: 12px;
    gap: 6px;
    color: #999999;
}

.fndFooterinr .fndFooterli svg {
    width: 20px;
    height: 20px;
}

.fndFooterli.active {
    color: #000;
}

@media (max-width: 359px) {
    .fndFooterinr {
        padding: 10px 15px;
    }
}

/*-----------------------------------------------------
    User Location Modal Css
-----------------------------------------------------*/
.usrLocMdl .modal-content h3,
.usrLocMdl .modal-content p.dislc {
    font-size: 18px;
    line-height: 18px;
    font-weight: 600;
    color: #000;
}

.usrLocMdl .modal-content p {
    font-size: 13px;
    line-height: 18px;
}

.lcflx button {
    width: 100%;
    margin-top: 5px;
}

.skipbtn {
    background-color: transparent;
    border: 1px solid black;
    color: black;
    padding: 12px 0px;
}

.fnbDate {
    margin: 30px 10px;
}

.fnbDeskbtn .btn-proceeds {
    width: 90%;
    cursor: pointer;
    display: block;
    margin: 15px auto;
}

.hdrsearch {
    cursor: pointer;
}

/*-------------------------------------------------------------
Sidebar Css
-------------------------------------------------------------*/
.centerSiderbar.p-sidebar {
    border-radius: 8px;

}

.centerSiderbar .p-sidebar-content {
    max-width: 550px;
}

/*-------------------------------------------------------------
Fixed FNB and Curated Shows Home Css
-------------------------------------------------------------*/
.orderFNBFixed {
    display: flex;
    align-items: center;
    gap: 20px;
    background-color: #ffffff;
    border-radius: 6px;
    position: fixed;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
    padding: 15px;
    box-shadow: 0 4px 20px hsl(0deg 0% 0% / 40%);
    z-index: 1000;
}

.orderFNBFixedmn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 20px;
    width: 200px;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 6px;
    cursor: pointer;
}

.orderFNBFixedmn.orderFNB {
    background-image: url('../../assets/food/orderfnbhome.png');
}

.orderFNBFixedmn.curatedShowsPVR {
    background-image: url('../../assets/food/curatedshowshome.png');
    /* border: 1px solid #FFCB05; */
}

.orderFNBFixedmn.curatedShowsINOX {
    background-image: url('../../assets/food/curatedshowshomeinox.png');
    /* border: 1px solid #FFCB05; */
}

.orderFNBFixedinr {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    z-index: 1;
}

.orderFNBFixedinr h4 {
    font-weight: 600;
    font-size: 16px;
    color: #000;
    margin-bottom: 0;
    flex-shrink: 0;
}

.orderFNB .orderFNBFixedinr h4 {
    text-transform: uppercase;
    color: #fff;

}

.curatedShows .orderFNBFixedinr h4 {
    color: #000;
}

.orderFNBFixedinr img {
    width: 20px;
    height: auto;
    flex-shrink: 0;
}

.dskfd {
    padding: 10px 0;
}

/*-------------------------------------------------------------
History Css
-------------------------------------------------------------*/
.no-scroll {
    overflow: hidden;
}

.showmorebtn {
    background-color: #FFF;
    color: #222;
    border-radius: 25px;
    padding: 12px 20px;
    margin: 15px auto;
    display: block;
    border: 1px solid #dbdbdb;
    box-shadow: 0 4px 10px #dbdbdb;
    font-size: 14px;
    line-height: 14px;
}

.flter-container {
    background-color: #ede9ea;
    border-radius: 25px;
    padding: 0 5px;
    margin: 15px 0 12px;
}

.flterinr {
    display: flex;
    align-items: center;
    overflow: auto;
    padding: 5px 0;
}

.flter-button {
    font-size: 13px;
    padding: 5px 16px;
    margin: 0;
    border: none;
    color: #000;
    background: transparent;
    cursor: pointer;
    border-radius: 20px;
    transition: background-color 0.3s ease, color 0.3s ease;
    font-weight: 600;
    flex-shrink: 0;
}

.flter-button.active {
    background-color: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

/* .flter-button:not(.active) {
    color: #555;
} */

/* .flter-button:not(.active):hover {
    color: black;
} */

/*-------------------------------------------------------------
Media Queries Css
-------------------------------------------------------------*/
@media (max-width: 1024px) {
    .cmnbtn {
        padding: 10px 15px;
        width: 100%;
    }

    #foodbooking-content {
        padding-bottom: 90px;
    }

    .fixbtmbtn {
        position: fixed;
    }

    .centerModal p.cmnUpfnt,
    .cmnUpfnt {
        color: #000;
    }

    .movieImg img {
        border-radius: 5px;
    }

    .movieupcmngdetails {
        border: 1px solid #E1E1E1;
        border-radius: 6px;
        padding: 10px;
        gap: 10px;
    }

    .ordstatus {
        background-color: transparent;
        font-size: 13px;
        font-weight: 500;
        border: 0;
        margin: 0;
        border-radius: 0;
    }

    .mobfd {
        box-shadow: 0 -2px 4px hsl(0deg 0% 0% / 10%);
        padding: 15px;
    }

    .bkngdlts,
    .totalsmry {
        margin-right: 0;
        margin-left: 0;
    }

    .getbtmbtn {
        margin: 0;
        margin-bottom: 20px;
    }


    .offer-cardOuter .p-tabview .p-tabview-nav {
        justify-content: unset;
    }


    .offer-cardOuter .p-tabview .p-tabview-panels,
    .contentPage {
        width: 100%;
    }

    .pyo-maindiv .swiper-button-next,
    .pyo-maindiv .swiper-rtl .swiper-button-prev,
    .swiper-button-prev,
    .swiper-rtl .swiper-button-next {
        display: none;
    }
}